<template>
  <!-- 9.0调度 -->
  <div
    v-loading="pageLoading"
    class="dispatch-management"
    ref="dispatchManagement"
  >
    <el-tabs type="border-card" v-model="activeName" @tab-click="tabClickFun">
      <el-tab-pane label="调度" class="el-tab-pane1" name="dispath">
        <div class="dispath-content" ref="dispathContent">
          <div ref="form" class="form-area">
            <analysisForm
              ref="analysisForm"
              :isNeedCarCph="true"
              :isNeedDriver="true"
              :isNeedCheckbox="true"
              :isNeedCompany="true"
              @getFormVal="onSearch"
            ></analysisForm>
          </div>
          <Table
            v-if="activeName == 'dispath'"
            ref="Table"
            :table-data="tableData"
            :title-name="titleName"
            :operation="true"
            :table-height="tableHeight"
            :isExpand="true"
            operation-width="300"
            :expands="expands"
            :tableRowClassName="tableRowClassName"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                sort="primary"
                :disabled="
                  isDisabled || scope.scopeRow.shiftIntercityStatus === 1
                "
                @click="shendCarFun(scope.scopeRow)"
                >派车</el-button
              >
              <el-button
                type="text"
                size="small"
                sort="primary"
                :disabled="
                  isDisabled ||
                  scope.scopeRow.shiftIntercityStatus === 1 ||
                  scope.scopeRow.surplusTicket < 0
                "
                @click="changeShiftFun(scope.scopeRow)"
                >改班</el-button
              >
              <el-button
                v-if="listFind('订票')"
                type="text"
                size="small"
                sort="primary"
                :disabled="
                  isDisabled ||
                  scope.scopeRow.shiftIntercityStatus === 1 ||
                  scope.scopeRow.surplusTicket < 0
                "
                @click="createdOrder(scope.scopeRow)"
                >订票</el-button
              >
              <el-button
                v-if="listFind('预留')"
                type="text"
                size="small"
                sort="primary"
                :disabled="
                  isDisabled ||
                  scope.scopeRow.shiftIntercityStatus === 1 ||
                  scope.scopeRow.surplusTicket < 0
                "
                @click="recevedTicket(scope.scopeRow)"
                >预留</el-button
              >
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="showShiftPassengerAll(scope.scopeRow)"
                >查看乘客</el-button
              >
              <!-- <el-button
                type="text"
                size="small"
                sort="primary"
                @click="shiftLogs(scope.scopeRow)"
                >班次日志</el-button
              > -->
            </template>
            <template slot="expandScope" slot-scope="{ scopeRow }">
              <div class="card-content" v-if="scopeRow.driverIntercityOrders">
                <div
                  v-for="(element, index) in scopeRow.driverIntercityOrders"
                  :key="index"
                  class="content-item"
                >
                  <div
                    class="item-row"
                    @click.stop="handelDriverCard(element, scopeRow)"
                  >
                    <div
                      :class="[
                        'card-status',
                        computedClass(element.orderStatus),
                      ]"
                    >
                      {{ computedStatus(element.orderStatus) }}
                    </div>
                    <div class="card-times">
                      {{
                        scopeRow.shiftType == 0
                          ? scopeRow.startDepartureTime
                          : computedTime(element.driverOrderAlias)
                      }}
                    </div>
                    <div class="card-driver">
                      {{ element.driverName }}({{ element.driverPhone }})
                    </div>
                    <div class="card-cph">
                      {{ element.licensePlateNumber }}
                    </div>
                    <div class="card-passenger-num">
                      乘客({{ computesPassengerNum(element) }})
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Table>
        </div>
      </el-tab-pane>
      <el-tab-pane
        :label="`待派单乘客(${passengerNum})`"
        class="el-tab-pane1"
        name="passenger"
      >
        <div class="dispath-content" ref="dispathContent1">
          <div ref="formPassenger" class="form-area">
            <analysisForm
              ref="analysisFormPassenger"
              @getFormVal="onSearchPassenger"
              :isNeedPassenger="true"
              :isNeedTimes="true"
              :isNeedMethod="true"
            ></analysisForm>
          </div>
          <Table
            v-if="activeName == 'passenger'"
            ref="TablePassenger"
            :table-data="tableDataPassenger"
            :title-name="titleName1"
            :operation="true"
            :table-height="tableHeight"
            operation-width="100"
          >
            <template slot-scope="scope">
              <el-button
                :disabled="isDisabled"
                type="text"
                size="small"
                sort="primary"
                @click="sendCarPassengerFun(scope.scopeRow)"
                >派车</el-button
              >
            </template>
          </Table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 派车、改班弹窗 -->
    <el-dialog
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :width="dialogVisibleWidth"
      :title="dialogTitle"
      @close="closePopupFun"
    >
      <changeShiftPopup
        ref="changeShiftPopup"
        :shiftIntercityObj="shiftIntercityObj"
        v-if="refsIdentification == 'changeShiftPopup'"
        @getFormResult="getFormResult"
      ></changeShiftPopup>
      <sendCarPopup
        ref="sendCarPopup"
        v-if="refsIdentification == 'sendCarPopup'"
        :shiftIntercityObj="shiftIntercityObj"
        @getFormResult="getFormResult"
      ></sendCarPopup>
      <sendPassengerCarPopup
        ref="sendPassengerCarPopup"
        :passengerObj="passengerObj"
        :departureDate="searchForm.departureDate"
        @save="saveDispathPassengers"
        @close="closePopupFun"
        v-if="refsIdentification == 'sendPassengerCarPopup'"
      ></sendPassengerCarPopup>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="closePopupFun"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="loading"
          @click="handleMakesure"
          >确认</el-button
        >
      </span>
    </el-dialog>
    <!-- 预留/订票 -->
    <reservedFormPopup
      v-if="showForm"
      ref="reserveForm"
      :header-text="headerText"
      :createOrderArgument="shiftIntercityObj"
      @closePopupFun="closePopAndUpDate"
      @saveOk="closePopAndUpDate"
    />
    <!-- 车次信息 -->
    <shiftInfo
      ref="changeSendCar"
      v-if="dialogVisibleChangeShift"
      :shiftIntercityObj="shiftIntercityObj"
      :itemData="driverIntercityOrdersItem"
      @backClose="closeVisibleFun"
      @deleteShift="deleteShift"
      @getFormResult="getDriverFormResult"
      @sendPassenger="sendCarPassengerFun"
    ></shiftInfo>
  </div>
</template>
<script>
import {
  getLineShiftOrderSchedulesAPI, //整合前，弃用
  getLineShiftOrderSchedulesEncapsulationAPI, //整合后
  dispatchDriverVehiclesAPI,
  getNotSchedulePassengerOrdersAPI, //待派单乘客
  dispatchChangePassengersAPI, //改派
  updateShiftAPI, //改班
  getNotSchedulePassengerOrderTicketNumAPI, //获取乘客数量
  dispatchPassengersAPI, //派乘客
  deleteDriverOrderAPI,
} from "@/api/lib/remouldAPI.js";
import analysisForm from "./components/analysisForm.vue";
import sendCarPopup from "./components/sendCarPopup.vue";
import sendPassengerCarPopup from "./components/sendPassengerCarPopup.vue";
import changeShiftPopup from "./components/changeShiftPopup.vue";
import reservedFormPopup from "./components/reservedFormPopup.vue";
import shiftInfo from "./components/shiftInfo.vue";
export default {
  components: {
    analysisForm,
    sendCarPopup,
    sendPassengerCarPopup,
    changeShiftPopup,
    reservedFormPopup,
    shiftInfo,
  },
  data() {
    return {
      passengerNum: 0,
      quickDays: 0,
      searchForm: {},
      tableData: [],
      tableDataPassenger: [],
      titleName: [
        {
          title: "班次时间",
          width: "120",
          SpecialJudgment: (res) => {
            let timeStr = res.startDepartureTime;
            //定班只显示开始时间
            if (res.shiftType === 1) {
              timeStr += "-" + res.endDepartureTime;
            }
            return timeStr;
          },
        },
        {
          title: "线路",
          width: 300,
          render: (h, params, index) => {
            //线路方向 1-去程 2-返程
            return h("div", {
              domProps: {
                innerHTML: `${
                  params.lineDirection === 1
                    ? `<div style="text-align:left;"><text style="width:22px;height:18px;line-height:18px;margin-right:8px;font-size:12px;color:#FFFFFF;background:#00C777;padding:3px;border-radius:4px;display:inline-block;text-align:center;">去</text>${params.lineName}</div>`
                    : `<div style="text-align:left;"><text style="width:22px;height:18px;line-height:18px;margin-right:8px;font-size:12px;color:#FFFFFF;background:#FF8627;padding:3px;border-radius:4px;display:inline-block;text-align:center;">返</text>${params.lineName}</div>`
                }`,
              },
            });
          },
        },
        {
          title: "票位/已售/预留/锁票",
          SpecialJudgment: (res) => {
            let numStr =
              res.ticketNum +
              " / " +
              res.soldTicket +
              " / " +
              res.reservedTicket +
              " / " +
              res.lockTicketNum;
            return numStr;
          },
        },
        {
          title: "余票",
          // props: "surplusTicket",
          render: (h, params, index) => {
            return h("span", {
              domProps: {
                innerHTML: `<span style='color:#FE602D'>${params.surplusTicket}</span>`,
              },
            });
          },
        },
        {
          title: "待派单乘客",
          props: "waitDispatchTicketNum",
        },
        {
          title: "班次状态",
          render: (h, params, index) => {
            return h("div", {
              domProps: {
                innerHTML: `${
                  params.shiftIntercityStatus === 0
                    ? `<div style='display:flex;align-items: center;justify-content: center;color:#05C46D;'><div style="background:#05C46D;display: inline-block; width:8px;height:8px;border-radius:4px; margin-right:8px"></div>正常</div>`
                    : params.shiftIntercityStatus === 1
                    ? `<div style='display:flex;align-items: center;justify-content: center;color:#FF0031;'><div style="background:#FF0031;display: inline-block; width:8px;height:8px;border-radius:4px; margin-right:8px"></div>停班</div>`
                    : `<div style='display:flex;align-items: center;justify-content: center;color:#7A8699;'><div style="background:#7A8699;display: inline-block; width:8px;height:8px;border-radius:4px; margin-right:8px"></div>停售</div>`
                }`,
              },
            });
          },
          // SpecialJudgment: res => {
          //   //班次状态：0-正常 1-撤班 2-暂停
          //   return res === 0 ? '正常' : res === 1 ? '停班' : res === 2 ? '停售' : '-'
          // }
        },
      ],
      titleName1: [
        {
          title: "出行时间",
          width: 150,
          SpecialJudgment: (res) => {
            let str = "";
            if (
              res.startAnticipationRidingTime == res.endAnticipationRidingTime
            ) {
              str = res.startAnticipationRidingTime.substring(11, 16);
            } else {
              str = `${res.startAnticipationRidingTime.substring(
                11,
                16
              )}-${res.endAnticipationRidingTime.substring(11, 16)}`;
            }
            return str;
          },
        },
        {
          title: "线路",
          width: 260,
          props: "lineName",
        },
        {
          title: "下单人",
          props: "passengerPhone",
          width: 160,
        },
        {
          title: "乘车人",
          props: "orderPhone",
          width: 160,
        },
        {
          title: "乘客数",
          props: "validPassengersNum",
          width: 80,
        },
        {
          title: "上车点",
          props: "startAddress",
        },
        {
          title: "下车点",
          props: "endAddress",
        },
        {
          title: "购票方式",
          props: "purchaseMethod",
          SpecialJudgment: (res) => {
            return res == 0
              ? "乘客购票"
              : res == 1
              ? "后台购票"
              : res == 2
              ? "快客码购票"
              : res == 3
              ? "Api购票"
              : "预留";
          },
        },
        {
          title: "订单编号",
          props: "id",
        },
      ],
      tableHeight: 0,
      total: 0,
      dialogVisible: false,
      dialogTitle: "",
      dialogVisibleWidth: "800px",
      refsIdentification: "", //弹窗标识
      shiftIntercityId: null,
      loading: false,
      activeName: "dispath",
      showForm: false,
      shiftIntercityObj: {},
      dispathTimer: null,
      expands: [],
      passengerId: null,
      dialogVisibleChangeShift: false,
      driverIntercityOrdersItem: null,
      isDisabled: false, //只能处理t-2的调度数据
      pageLoading: false,
      headerText: null,
      passengerObj: {},
    };
  },
  created() {},
  mounted() {
    this.pageLoading = true;
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
      // this.$refs.analysisForm.onSearch(); //初始化请求数据
      //数据量太大，默认取第一项线路查询班次列表
      this.$refs.analysisForm.defalutSeach();
    });
  },
  methods: {
    closePopAndUpDate() {
      this.showForm = false;
      this.getDataList();
    },
    getDriverFormResult(val) {
      dispatchChangePassengersAPI(val)
        .then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success("改派成功");
            this.closeVisibleFun();
          }
          this.$refs.changeSendCar.closeLoading();
        })
        .catch((err) => {
          this.$refs.changeSendCar.closeLoading();
        });
    },
    deleteShift(obj, flag) {
      let text = "";
      if (flag) {
        text = "确认取消派车";
      } else {
        text = "取消派车后，乘客订单进入待派单，系统会重新分配订单";
      }
      this.$confirm(text, "提示")
        .then(() => {
          //已发车的车次不可删除
          const data = {
            driverOrderId: obj.id,
            driverId: obj.driverId,
          };
          deleteDriverOrderAPI(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success("取消成功");
              this.closeVisibleFun();
            }
          });
        })
        .catch(() => {});
    },
    closeVisibleFun() {
      this.$route.meta.title = "调度";
      this.dialogVisibleChangeShift = false;
      this.driverIntercityOrdersItem = null; //初始化司机车次信息字段
      this.getDataList();
    },
    handelDriverCard(item, row) {
      //弹出车次改派的弹窗
      this.shiftIntercityObj = { ...row }; //班次信息
      this.driverIntercityOrdersItem = { ...item }; //司机车次信息
      this.dialogVisibleChangeShift = true;
      this.$route.meta.title = "车次信息";
    },
    tableRowClassName(row, rowIndex) {
      if (!row.row.driverIntercityOrders.length) {
        return "row-expand-cover";
      }
    },
    //计算司机班次乘客数
    computesPassengerNum(item) {
      let all = item.vehicleCarryPassengerNumber;
      let now =
        item.vehicleCarryPassengerNumber - item.surplusCarryPassengerNumber;
      return `${now}/${all}`;
    },
    //发车时间
    computedTime(times) {
      return times?.substring(11, 16);
    },
    //状态
    computedStatus(status) {
      let statusText = "";
      if (status === 40) {
        statusText = "已完成";
      } else if (20 < status && status < 40) {
        statusText = "行程中";
      } else if (status <= 20) {
        statusText = "待发车";
      }
      return statusText;
    },
    computedClass(status) {
      let calss = "";
      if (status === 40) {
        calss = "card-status-finish";
      } else if (20 < status && status < 40) {
        calss = "card-status-going";
      } else if (status <= 20) {
        calss = "card-status-waiting";
      }
      return calss;
    },
    // 班次日志
    shiftLogs(scope) {
      this.$router.push({
        path: "/projectTravel/dispatchManagement/shiftLogs",
        query: { id: scope.orderId },
      });
    },
    //确认按钮
    handleMakesure() {
      this.$refs[this.refsIdentification]?.saveFun();
    },
    saveDispathPassengers(result) {
      dispatchPassengersAPI(result).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message.success(res.msg);
          this.closePopupFun();
          this.getPassengerList();
        }
      });
    },
    //表单结果返回
    getFormResult(result) {
      this.loading = true;
      let queryPath = null;
      let tipsText = "";
      if (this.refsIdentification == "sendCarPopup") {
        queryPath = dispatchDriverVehiclesAPI;
        tipsText = "派车成功";
      } else if (this.refsIdentification == "changeShiftPopup") {
        queryPath = updateShiftAPI;
        tipsText = "改班成功";
      }
      queryPath(result)
        .then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success(tipsText);
            this.closePopupFun();
            //刷新列表
            this.getDataList();
          } else {
            this.$message.error(res.msg);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    closePopupFun() {
      this.dialogVisible = false;
      this.refsIdentification = "";
      //如果是查看乘客页面，需要刷新页面数据
      if (this.dialogVisibleChangeShift) {
        this.$refs.changeSendCar.queryPassengerList();
      }
    },
    //调度-查看班次总的乘客信息
    showShiftPassengerAll(row) {
      //弹出车次改派的弹窗
      this.shiftIntercityObj = { ...row }; //班次信息
      this.dialogVisibleChangeShift = true;
      this.$route.meta.title = "查看乘客";
    },
    //调度-预留
    recevedTicket(row) {
      this.shiftIntercityObj = { ...row };
      this.headerText = "预留";
      this.showForm = true;
    },
    //调度-订票
    createdOrder(row) {
      this.shiftIntercityObj = { ...row };
      this.showForm = true;
      this.headerText = "订票";
    },
    //调度-改班
    changeShiftFun(row) {
      this.shiftIntercityObj = { ...row };
      this.dialogTitle = "改班";
      this.refsIdentification = "changeShiftPopup"; //ref标识
      this.dialogVisibleWidth = "500px";
      this.dialogVisible = true;
    },
    //调度-派车
    shendCarFun(row) {
      //票位数如果等于已派车最大载客数之和 则不可以派车
      let vehicleCarryPassengerNumberSum = 0;
      if (row.driverIntercityOrders?.length) {
        row.driverIntercityOrders.map((item) => {
          vehicleCarryPassengerNumberSum += item.vehicleCarryPassengerNumber; //最大载客数
        });
      }
      if (vehicleCarryPassengerNumberSum === row.ticketNum) {
        this.$message.error("请先增加票位，再派车");
        return;
      }
      this.shiftIntercityObj = { ...row };
      this.dialogTitle = "派车";
      this.refsIdentification = "sendCarPopup"; //ref标识
      this.dialogVisibleWidth = "70vw";
      this.dialogVisible = true;
    },
    //代接乘客派车
    sendCarPassengerFun(row) {
      this.dialogTitle = "派车";
      this.passengerObj = { ...row };
      this.refsIdentification = "sendPassengerCarPopup"; //ref标识
      this.dialogVisibleWidth = "800px";
      this.dialogVisible = true;
    },
    // 点击查询
    onSearch(val) {
      this.searchForm = { ...val };
      //只能操作T-2的调度数据
      let now = new Date().getTime();
      let searchT = new Date(val.departureDate).getTime();
      this.isDisabled = now > searchT + 3600 * 24 * 2 * 1000;
      this.getDataList();
    },
    onSearchPassenger(val) {
      let now = new Date().getTime();
      let searchT = new Date(val.departureDate).getTime();
      this.isDisabled = now > searchT + 3600 * 24 * 2 * 1000;
      this.searchForm = { ...val };
      this.getPassengerList();
    },
    computeHeight() {
      if (this.activeName == "dispath") {
        let wholeHeight = this.$refs.dispathContent.clientHeight;
        let formHeight = this.$refs.form.clientHeight;
        this.tableHeight = wholeHeight - 12 - formHeight - 4;
      } else {
        let wholeHeight = this.$refs.dispathContent1.clientHeight;
        let formHeight = this.$refs.formPassenger.clientHeight;
        this.tableHeight = wholeHeight - 12 - formHeight - 4;
      }
    },
    tabClickFun(val) {
      if (this.activeName === "passenger") {
        //把另外的页面的传参进来保持
        this.$refs.analysisFormPassenger.changeFormValue(this.searchForm);
        this.$nextTick(() => {
          this.$refs.analysisFormPassenger.onSearch();
        });
      } else {
        this.$refs.analysisForm.changeFormValue(this.searchForm);
        this.$nextTick(() => {
          this.$refs.analysisForm.onSearch();
        });
      }
    },
    getDataList() {
      this.dispathTimer && clearInterval(this.dispathTimer);
      this.dispathTimer = null;
      this.queryDataFun();
      this.dispathTimer = setInterval(() => {
        this.queryDataFun();
      }, 60 * 1000);
    },
    queryDataFun() {
      this.queryPaasengerAllNum();
      getLineShiftOrderSchedulesAPI(this.searchForm)
        .then((res) => {
          if (res.code === "SUCCESS") {
            let resultData = res.data || [];
            //设置id是展开行的row-key设置的是id,且是唯一值
            let ids = [];
            resultData = resultData.map((item) => {
              if (item.driverIntercityOrders.length) {
                ids.push(item.shiftIntercityId);
              }
              return { ...item, id: item.shiftIntercityId };
            });
            this.expands = [...ids]; //设置默认展开的子项
            this.tableData = [...resultData];
            this.$refs.Table.doLayout();
          }
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
        });
    },
    queryPaasengerAllNum() {
      getNotSchedulePassengerOrderTicketNumAPI(this.searchForm).then((res) => {
        if (res.code === "SUCCESS") {
          this.passengerNum = res.data || 0;
        }
      });
    },
    getPassengerList() {
      this.dispathTimer && clearInterval(this.dispathTimer);
      this.dispathTimer = null;
      this.queryPassengerFun();

      this.dispathTimer = setInterval(() => {
        this.queryPassengerFun();
      }, 60 * 1000);
    },
    queryPassengerFun() {
      this.queryPaasengerAllNum();
      getNotSchedulePassengerOrdersAPI(this.searchForm).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableDataPassenger = res.data || [];
        }
      });
    },
  },
  destroyed() {
    clearInterval(this.dispathTimer);
    window.removeEventListener("resize", this.computeHeight);
  },
};
</script>
<style lang="scss" scoped>
.dispatch-management {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  position: relative;
  .form-area {
    margin-bottom: 16px;
  }
  .card-content {
    height: 100%;
    width: 100%;
    background: #dfe2e6;
    box-sizing: border-box;
    padding: 20px 30px 8px 60px;
    display: flex;
    // justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .content-item {
      display: flex;
      margin-bottom: 12px;
      width: calc(33% - 8px);
    }
    .item-row {
      display: flex;
      flex-wrap: nowrap;
      border: 1px solid #00c777;
      border-radius: 4px;
      background: #ffffff;
      padding: 4px;
      color: #0b1838;
      line-height: 24px;
      cursor: pointer;
      .card-status {
        font-size: 12px;
        border-radius: 4px;
        padding: 2px 4px;
        width: 58px;
        text-align: center;
        line-height: 20px !important;
      }
      .card-status-finish {
        color: #ffffff;
        background: #336ffe;
      }
      .card-status-going {
        background: #00c777;
        color: #ffffff;
      }
      .card-status-waiting {
        background: #ff8627;
        color: #ffffff;
      }
      .card-times {
        margin: 0 15px;
      }
      .card-cph {
        margin: 0 10px 0 8px;
      }
      .card-passenger-num {
        color: #336ffe;
      }
    }
  }
}
.el-tabs {
  height: 100%;
  .el-tab-pane1 {
    width: 100%;
    display: flex;
    height: 100%;
    .dispath-content {
      width: 100% !important;
    }
  }
}
/deep/.el-tabs__content {
  width: 100% !important;
  height: calc(100% - 70px);
  padding: 15px 0 0;
  .row-expand-cover td:first-child .el-icon-arrow-right {
    visibility: hidden;
  }
}

.self-footer {
  padding-left: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Table {
  width: 100%;
}
/deep/.el-loading-mask {
  background: rgba(255, 255, 255, 0.2);
}
</style>
