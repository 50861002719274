<template>
  <div class="ticket-change">
      <div class="exit" @click.stop="returnFun">
        <i class="iconfont its_ba_fanhui"></i>
      </div>
      <el-steps
        :active="ticketChangeActive"
        align-center
        finish-status="success"
      >
        <el-step
          title="确认改签信息"
          description="请确认乘客信息是否有误"
        ></el-step>
        <el-step
          :title="ticketChangeActive == 0 ? '改签班次' : '确认新班次'"
          description="修改新的班次"
        ></el-step>
        <el-step title="完成" description></el-step>
      </el-steps>
      <phoneOrderDetial
        ref="phoneOrderDetialRef"
        v-if="ticketChangeActive !== 2"
        from="ticketChange"
        :step="ticketChangeActive"
        :ticketChangeForm="ticketChangeForm"
        @placeOrder="placeOrder"
        @watchOrderAmount="watchOrderAmount"
      ></phoneOrderDetial>
      <div v-else class="result-box">
        <div v-if="isTicketChange" class="success-text">
          <img src="../../../../assets/images/home/success.svg" />
          <span>改签成功</span>
        </div>
        <div v-else class="error-text">
          <img src="../../../../assets/images/home/error.svg" />
          <span>改签失败</span>
        </div>
      </div>
      <div class="button-box">
        <div v-show="ticketChangeActive == 0">
          <el-button @click="returnFun">取消并返回</el-button>
          <el-button type="primary" @click="nextFun">信息确认无误</el-button>
        </div>
        <div v-show="ticketChangeActive == 1">
          <el-button @click="previousFun">上一步</el-button>
          <el-button
            type="primary"
            :disabled="isDisabledConfirmTicketFlag"
            @click="confirmTicketChangeFun"
            >确认改签</el-button
          >
        </div>
        <div v-show="ticketChangeActive == 2">
          <el-button type="primary" @click="returnFun('isRequest')"
            >返回上一页</el-button
          >
        </div>
      </div>
    </div>
</template>
<script>
import phoneOrderDetial from "../phoneOrderDetial.vue";
export default {
  components:{
    phoneOrderDetial
  },
  props:{
    ticketChangeForm:{
      typeof:Object,
      default:()=>{}
    }
  },
  data(){
    return{
      ticketChangeActive: 0, //改签步骤条
      isTicketChange:false,
      isDisabledConfirmTicketFlag:true,
    }
  },
  mounted(){

  },
  methods:{
    //返回按钮
    returnFun(flag) {
      this.$route.meta.title = "查看乘客";
      this.ticketChangeActive = 0;
      this.isShowTicketChange = false;      
      this.$emit('close');
    },
    //确认改签
    confirmTicketChangeFun() {
      this.$refs.phoneOrderDetialRef.placeOrder();
    },
    placeOrder(val) {
      if (val == "SUCCESS") {
        this.isTicketChange = true;
      } else {
        this.isTicketChange = false;
      }
      this.ticketChangeActive = 2;
    },
    //监听订单金额的变化
    watchOrderAmount(val) {
      this.isDisabledConfirmTicketFlag = val;
    },
    //步骤条下一步
    nextFun() {
      this.ticketChangeActive = 1;
    },
    //步骤条上一步
    previousFun() {
      this.$refs.phoneOrderDetialRef.$refs.ruleForm.resetFields();
      this.$refs.phoneOrderDetialRef.getTicketChangeFormFun();
      this.ticketChangeActive = 0;
    },
  }
}
</script>
<style lang="scss" scoped>
  .ticket-change {
    width: 100%;
    height: calc(100% - 50px);
    overflow: scroll;
    position: absolute;
    top: 50px;
    left: 0;
    z-index: 200;
    background: #ffffff;
    .exit {
      width: 32px;
      height: 32px;
      color: #333333;
      font-size: 15px;
      background-color: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
      position: fixed;
      top: 80px;
      z-index: 300;
      i {
        cursor: pointer;
      }
    }

    .title {
      width: 100%;
      height: 56px;
      line-height: 56px;
      padding-left: 10px;
      margin-bottom: 50px;
      border-bottom: 1px solid #dcdedf;
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      box-sizing: border-box;
    }

    .button-box {
      border-top: 1px solid #dcdedf;
      padding-top: 16px;
      display: flex;
      justify-content: center;
    }

    .result-box {
      width: 100%;
      height: 560px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 600;

      div {
        display: flex;
        align-items: center;

        span {
          margin-left: 12px;
        }
      }

      .success-text {
        color: #2da641;
      }

      .error-text {
        color: #d40000;
      }
    }

    ::v-deep .is-text {
      border: none;
      color: #ffffff;
    }

    ::v-deep .el-step__main {
      .is-wait {
        color: #969799;
      }

      .el-step__description {
        color: #969799;
      }

      .el-step__title {
        color: #303133;
      }
    }

    ::v-deep .is-process {
      .is-text {
        background: #336ffe;
      }
    }

    ::v-deep .is-wait {
      .is-text {
        background: #c8c9cc;
      }
    }

    ::v-deep .is-success {
      .is-text {
        border: 2px solid #336ffe;
        color: #336ffe;
      }

      .el-step__line {
        border-color: #336ffe;
        background-color: #336ffe;
      }
    }
  }
</style>