var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "change-shift" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "线路：" } },
            [
              _c("el-input", {
                attrs: { disabled: "" },
                model: {
                  value: _vm.form.lineName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "lineName", $$v)
                  },
                  expression: "form.lineName",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.formList, function (element, index) {
            return _c(
              "el-form-item",
              {
                key: index,
                attrs: { label: element.label, prop: element.props },
              },
              [
                element.props === "startDepartureDateTime"
                  ? [
                      _c("el-time-picker", {
                        attrs: {
                          format: "HH:mm",
                          "value-format": "HH:mm",
                          placeholder: "选择班次开始时间",
                          clearable: false,
                          disabled: _vm.isSoldDisbled,
                        },
                        on: { change: _vm.handleStartTime },
                        model: {
                          value: _vm.form[element.props],
                          callback: function ($$v) {
                            _vm.$set(_vm.form, element.props, $$v)
                          },
                          expression: "form[element.props]",
                        },
                      }),
                    ]
                  : _vm._e(),
                element.props === "endDepartureDateTime"
                  ? [
                      _c("el-time-picker", {
                        attrs: {
                          format: "HH:mm",
                          "value-format": "HH:mm",
                          disabled:
                            _vm.shiftIntercityObj.shiftType === 0 ||
                            _vm.isSoldDisbled,
                          placeholder: "选择班次结束时间",
                          clearable: false,
                        },
                        model: {
                          value: _vm.form[element.props],
                          callback: function ($$v) {
                            _vm.$set(_vm.form, element.props, $$v)
                          },
                          expression: "form[element.props]",
                        },
                      }),
                    ]
                  : _vm._e(),
                element.props === "status"
                  ? [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form[element.props],
                            callback: function ($$v) {
                              _vm.$set(_vm.form, element.props, $$v)
                            },
                            expression: "form[element.props]",
                          },
                        },
                        _vm._l(_vm.statusList, function (role) {
                          return _c("el-option", {
                            key: role.value,
                            attrs: { label: role.label, value: role.value },
                          })
                        }),
                        1
                      ),
                    ]
                  : _vm._e(),
                ["ticketNum", "reservedTicket"].includes(element.props)
                  ? [
                      _c("el-input-number", {
                        attrs: {
                          min:
                            element.props === "ticketNum"
                              ? _vm.ticketNumMin
                              : 0,
                          precision: 0,
                          max:
                            element.props === "reservedTicket"
                              ? _vm.lockTicketMax
                              : 999,
                        },
                        model: {
                          value: _vm.form[element.props],
                          callback: function ($$v) {
                            _vm.$set(_vm.form, element.props, $$v)
                          },
                          expression: "form[element.props]",
                        },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }