<template>
  <div class="analysisForm">
    <el-form
      ref="searchForm"
      :inline="true"
      :model="searchForm"
      :rules="rules"
      label-position="right"
      label-width="96px"
      :class="['demo-form-inline', isNeedCompany ? 'style1' : 'style2']"
    >
      <el-form-item v-if="isNeedCompany" label="所属公司：" prop="companyId">
        <companySelect
          :clearable="true"
          :company-tree="companyList"
          @getValue="getGroupId"
        ></companySelect>
      </el-form-item>
      <el-form-item label="线路：" prop="lineId">
        <el-select
          v-model="searchForm.lineId"
          clearable
          filterable
          placeholder="请输入搜索选择"
          @change="handelLinesChange"
          @focus="handelFocus"
        >
          <el-option
            v-for="item in lineListData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button
          style="margin-left: 8px"
          icon="el-icon-refresh"
          circle
          size="mini"
          @click="changeDirection"
        ></el-button>
      </el-form-item>
      <el-form-item label="发车日期：" prop="departureDate">
        <el-button
          icon="el-icon-arrow-left"
          circle
          size="mini"
          @click="handelDays(-1)"
        ></el-button>
        <el-date-picker
          v-model="searchForm.departureDate"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          type="date"
          placeholder="发车日期"
          @change="daysChange"
          :clearable="false"
        ></el-date-picker>
        <el-button
          icon="el-icon-arrow-right"
          circle
          size="mini"
          @click="handelDays(1)"
        ></el-button>
        <el-radio-group v-model="quickDays">
          <el-radio :label="0">今天</el-radio>
          <el-radio :label="1">明天</el-radio>
          <el-radio :label="2">后天</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-if="isNeedCheckbox">
        <el-checkbox
          v-model="searchForm.onlySeeWaitDispatch"
          style="margin-left: 20px"
          @change="onSearch()"
          >查看待派单班次</el-checkbox
        >
      </el-form-item>
      <el-form-item label="出行时间：" v-if="isNeedTimes" prop="travelTime">
        <el-select
          v-model="searchForm.travelTime"
          placeholder="请选择"
          clearable
          @change="handeltravelTimeChange"
        >
          <el-option
            v-for="item in timeOptions"
            :key="item.startDepartureDateTime"
            :label="`${item.startDepartureDateTime}-${item.endDepartureDateTime}`"
            :value="item.startDepartureDateTime"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="isNeedPassenger" label="联系方式：">
        <el-input
          v-model="searchForm.phone"
          type="input"
          placeholder="请输入"
          @input="handelphones"
        ></el-input>
      </el-form-item>
      <el-form-item label="车牌号：" v-if="isNeedCarCph">
        <el-select
          v-model="searchForm.licensePlateNumber"
          filterable
          clearable
          value-key="id"
          placeholder="请输入车牌号"
          @clear="onSearch()"
          @change="handleAutocomplete"
        >
          <el-option
            v-for="single in cphList"
            :key="single.id"
            :label="single.licensePlateNumber"
            :value="single"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="司机姓名：" v-if="isNeedDriver">
        <el-select
          v-model="searchForm.driverName"
          filterable
          clearable
          value-key="driverId"
          placeholder="请输入司机姓名"
          @clear="onSearch()"
          @change="changeDriverNameFun"
        >
          <el-option
            v-for="single in driverList"
            :key="single.driverId"
            :label="single.driverName"
            :value="single"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="购票方式：" v-if="isNeedMethod">
        <el-select
          v-model="searchForm.purchaseMethod"
          collapse-tags
          clearable
          placeholder="请选择购票方式"
          @change="onSearch"
        >
          <el-option
            v-for="item in sourceTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" size="small" @click="onSearch"
          >查询</el-button
        >
      </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
import {
  getAllLine,
  driverIntercityPageAPI,
  getVehicleList,
  getCompanyTree,
} from "@/api/lib/api.js";
import { getReverseLineAPI } from "@/api/lib/remouldAPI.js";
import companySelect from "@/components/treeSelect/companySelect.vue";
export default {
  components: {
    companySelect,
  },
  props: {
    isNeedPassenger: {
      type: Boolean,
      default: false,
    },
    isNeedTimes: {
      type: Boolean,
      default: false,
    },
    isNeedCarCph: {
      type: Boolean,
      default: false,
    },
    isNeedDriver: {
      type: Boolean,
      default: false,
    },
    isNeedCheckbox: {
      type: Boolean,
      default: false,
    },
    isNeedCompany: {
      type: Boolean,
      default: false,
    },
    isNeedMethod: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchForm: {
        departureDate: new Date(),
        lineId: null,
        travelTime: "",
        phone: "", //手机号
        licensePlateNumber: undefined,
        driverName: undefined,
        onlySeeWaitDispatch: false,
        purchaseMethod: null,
        companyId: null,
      },
      companyList: [],
      timeOptions: [],
      quickDays: 0,
      rules: {
        departureDate: [
          { required: true, message: "请选择日期", trigger: "blur" },
        ],
      },
      lineListData: [],
      lineDirection: null, //线路方向 1-去程 2-返程 数据
      driverList: [],
      cphList: [],
      //购票方式
      sourceTypeList: [
        {
          value: 0,
          label: "乘客购票",
        },
        {
          value: 1,
          label: "后台购票",
        },
        {
          value: 2,
          label: "快客码购票",
        },
        {
          value: 3,
          label: "Api购票",
        },
        {
          value: 4,
          label: "预留",
        },
      ],
    };
  },
  watch: {
    quickDays(newValue) {
      if (newValue || newValue === 0) {
        this.searchForm.departureDate = this.GMTToStr(
          new Date().valueOf() + newValue * (3600 * 1000 * 24)
        );
      }
    },
    "searchForm.departureDate"(newVal, oldVal) {
      this.onSearch();
    },
  },
  created() {
    this.getCompanyList();
  },
  mounted() {
    this.creatTimesHM();
    this.queryCph();
    this.queryDriver();
  },
  methods: {
    queryCph() {
      getVehicleList({
        currentPage: 1,
        pageSize: 999,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.cphList = res.data.list;
        }
      });
    },
    queryDriver() {
      driverIntercityPageAPI({
        currentPage: 1,
        pageSize: 999,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.driverList = res.data.list;
        }
      });
    },
    // 企业回显
    getGroupId(v) {
      if (v == null) {
        this.searchForm.companyId = null;
      } else {
        this.searchForm.companyId = v;
        this.searchForm.lineId = null; //清空线路
        this.lineDirection = null; //清空返程数据
      }
      this.getLineList();
      this.onSearch();
    },
    // 获取企业树
    getCompanyList() {
      getCompanyTree({ parentId: sessionStorage.getItem("companyId") }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.companyList = res.data;
          }
        }
      );
    },
    changeDriverNameFun(item) {
      this.searchForm.driverName = item.driverName;
      this.onSearch();
    },
    handleAutocomplete(item) {
      this.searchForm.licensePlateNumber = item.licensePlateNumber;
      this.onSearch();
    },
    handelFocus() {
      this.getLineList(); //刷新列表数据
    },
    handeltravelTimeChange(val) {
      this.onSearch();
    },
    handelphones() {
      setTimeout(() => {
        this.onSearch();
      }, 300);
    },
    handelLinesChange(val) {
      this.onSearch();
      if (val) {
        getReverseLineAPI({ lineId: val }).then((res) => {
          if (res.code === "SUCCESS") {
            if (res.data?.id) {
              this.lineDirection = res.data;
            } else {
              this.lineDirection = null;
            }
          }
        });
      } else {
        this.getLineList();
      }
    },

    // 获取线路
    getLineList() {
      return getAllLine({
        isEnable: true,
        companyId: this.searchForm.companyId,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.lineListData = res.data;
        }
      });
    },
    //换向
    changeDirection() {
      //状态：0-正常 1-禁用
      if (this.lineDirection && this.lineDirection.status != 1) {
        this.searchForm.lineId = this.lineDirection.id;
        this.handelLinesChange(this.lineDirection.id);
        this.onSearch();
      } else {
        this.$message.error(
          this.searchForm.lineId ? "该线路没有关联线路" : "请先选择线路"
        );
      }
    },
    //创建时间数组
    creatTimesHM() {
      let timeArrays = new Array(24).fill({}).map((item, index) => {
        return {
          startDepartureDateTime: (index < 10 ? "0" + index : index) + ":00",
          endDepartureDateTime:
            (index + 1 < 10 ? "0" + (index + 1) : index + 1) + ":00",
        };
      });
      this.timeOptions = [...timeArrays];
    },
    async changeFormValue(val) {
      this.searchForm = Object.assign(this.searchForm, val);
      await this.getLineList();
      //如果是公司页需要传回公司
      // let tempCompany = null;
      // let onlySeeWaitDispatch = false;
      // if (this.isNeedCompany) {
      //   tempCompany = this.searchForm.companyId;
      //   onlySeeWaitDispatch = !!this.searchForm.onlySeeWaitDispatch;
      // }
      // this.searchForm = { ...val,onlySeeWaitDispatch:false };
      // if (this.isNeedCompany) {
      //   this.searchForm.companyId = tempCompany;
      //   this.searchForm.onlySeeWaitDispatch = onlySeeWaitDispatch;
      // }
      if (val.lineId) {
        this.handelLinesChange(val.lineId);
      }
      this.daysChange(val.departureDate);
      //这里涉及到两个值的改变，会触发两次请求-------
    },
    //初始化请求数据-默认取第一项
    async defalutSeach() {
      await this.getLineList();
      //数据量大，默认取第一项值，
      if (this.lineListData.length) {
        this.searchForm.lineId = this.lineListData[0].id;
        getReverseLineAPI({ lineId: this.searchForm.lineId }).then((res) => {
          if (res.code === "SUCCESS") {
            if (res.data?.id) {
              this.lineDirection = res.data;
            } else {
              this.lineDirection = null;
            }
          }
        });
      }
      let date = this.GMTToStr(this.searchForm.departureDate);
      const data = {
        departureDate: date,
        lineId: this.searchForm.lineId,
      };
      this.$emit("getFormVal", data);
    },
    onSearch() {
      this.$refs.searchForm.validate((valid) => {
        if (valid) {
          let date = this.GMTToStr(this.searchForm.departureDate);
          const data = {
            departureDate: date,
            lineId: this.searchForm.lineId,
          };
          if (this.isNeedPassenger) data.phone = this.searchForm.phone;
          if (this.isNeedCarCph)
            data.licensePlateNumber = this.searchForm.licensePlateNumber;
          if (this.isNeedDriver) data.driverName = this.searchForm.driverName;
          if (this.isNeedCheckbox)
            data.onlySeeWaitDispatch = this.searchForm.onlySeeWaitDispatch;
          if (this.isNeedMethod) {
            data.purchaseMethod = this.searchForm.purchaseMethod;
          }
          if (this.isNeedCompany) {
            data.companyId = this.searchForm.companyId;
          }
          if (this.isNeedTimes) {
            let startTime = null;
            let endTime = null;
            if (this.searchForm.travelTime) {
              let times = this.timeOptions.find(
                (item) =>
                  item.startDepartureDateTime === this.searchForm.travelTime
              );
              startTime = new Date(
                `${date} ${times.startDepartureDateTime}`
              ).getTime();
              if (times.endDepartureDateTime == "24:00") {
                times.endDepartureDateTime = "23:59:59";
              }
              endTime = new Date(
                `${date} ${times.endDepartureDateTime}`
              ).getTime();
            }
            data.startDepartureDateTime = startTime;
            data.endDepartureDateTime = endTime;
          }
          delete data.travelTime;
          this.$emit("getFormVal", data);
        }
      });
    },
    daysChange(val) {
      this.matchingQuickDay(val);
    },
    handelDays(type) {
      let time = this.searchForm.departureDate;
      this.searchForm.departureDate = this.GMTToStr(
        new Date(time).valueOf() + type * (3600 * 1000 * 24)
      );
      this.matchingQuickDay(this.searchForm.departureDate);
    },
    matchingQuickDay(value) {
      const today = this.GMTToStr(new Date().getTime());
      const tomorrow = this.GMTToStr(
        new Date().getTime() + 1 * 3600 * 1000 * 24
      );
      const todayAfterTomorrow = this.GMTToStr(
        new Date().getTime() + 2 * 3600 * 1000 * 24
      );
      if (value === today) {
        this.quickDays = 0;
      } else if (value === tomorrow) {
        this.quickDays = 1;
      } else if (value === todayAfterTomorrow) {
        this.quickDays = 2;
      } else {
        this.quickDays = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  width: 100%;
  display: grid;
  grid-template-columns: 22% 36% 20% 20%;
  .el-radio-group {
    padding-bottom: 0;
    margin-left: 16px;
    .el-radio {
      margin-right: 12px;
    }
    .el-radio__label {
      padding-left: 2px;
    }
  }
}
/deep/.style1 {
  width: 100%;
  display: grid;
  grid-template-columns: 20% 22% 40% 20%;
}
/deep/.style2 {
  width: 100%;
  display: grid;
  grid-template-columns: 22% 37% 20% 20%;
}
</style>
