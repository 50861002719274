<template>
  <!-- 车辆模糊搜索 -->
  <el-select
    v-model="vehicleName"
    filterable    
    :clearable="clearable"
    value-key="id"
    placeholder="请输入车牌号"
    @clear="clearCph"
    @change="handleAutocomplete"
  >
    <el-option
      v-for="single in cphList"
      :key="single.id"
      :label="single.licensePlateNumber"
      :value="single"
    >
    </el-option>
  </el-select>
</template>
<script>
import {
  getVehicleList,
} from "@/api/lib/api.js";
export default {
  props: {
    //以哪个字段返回结果
    fieldName: {
      type: String,
      default: 'id'
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    /* 多选 */
    multiple: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    /**是否关联线路查询车辆 */
    lineId: {
      type: Number,
      default: null
    },
  },
  data () {
    return {
      vehicleName: null,
      cphList: [],
      vehicleId: null,
    }
  },
  created () {
    this.getCphList();
  },
  mounted () {

  },
  methods: {    
    getCphList () {
      getVehicleList({
        currentPage: 1,
        pageSize: 999,
        lineIds: this.lineId
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.cphList = res.data.list;
        }
      });
    },
    clearCph () {
      this.vehicleName = null;
    },
    handleAutocomplete (item) {      
      this.$emit('getVehiceValue', item[this.fieldName] || null,item.licensePlateNumber,item.seatNumber || null);
    },
  }
}
</script>
<style lang="scss" scoped>
</style>