var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: { filterable: "", placeholder: "请输入", clearable: "" },
      on: {
        clear: function ($event) {
          _vm.driverId = null
        },
        change: _vm.handleAutocomplete,
      },
      model: {
        value: _vm.driverId,
        callback: function ($$v) {
          _vm.driverId = $$v
        },
        expression: "driverId",
      },
    },
    _vm._l(_vm.driverList, function (item) {
      return _c("el-option", {
        key: item.driverId,
        attrs: { label: item.driverName, value: item[_vm.fieldName] },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }