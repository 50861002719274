<template>
  <!-- 司机模糊搜索 -->
  <el-select
    v-model="driverId"
    filterable
    placeholder="请输入"
    clearable
    @clear="driverId=null"
    @change="handleAutocomplete"
  >
    <el-option
      v-for="item in driverList"
      :key="item.driverId"
      :label="item.driverName"
      :value="item[fieldName]"
    >
    </el-option>
  </el-select>
</template>
<script>
import {
  driverIntercityPageAPI,
} from "@/api/lib/api.js";
export default {
  props:{
    //以哪个字段返回结果
    fieldName: {
      type: String,
      default: 'driverId'
    },
    licensePlateNumber:{
      type:String,
      default:""
    },
    /**是否需要关联车辆 */
    isRelation:{
      type:Boolean,
      default:true
    },
    /**只有一个司机可选的时候默认选中 */
    singleChoose:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      driverId:null,
      driverList:[],
    }
  },
  created () {

  },  
  methods: {
    clearDriver () {
      this.driverId = null; 
      if(this.licensePlateNumber){
        this.driverRelation(); 
      } else{
        this.driverList=[];
      }
    },
    // 人车关系
    driverRelation () {
      const queryData = {
        licensePlateNumber: this.licensePlateNumber,
        currentPage: 1,
        pageSize: 999,
        driveType:[0]
      }
      driverIntercityPageAPI(queryData).then((res) => {
        if (res.code == "SUCCESS") {
          this.driverList = res.data.list;
          if (res.data.list?.length === 1 && this.singleChoose) {
            this.driverId = res.data.list[0][this.fieldName];
            this.$emit('getDriverValue',this.driverId);
          }
        }
      });
    },
    handleAutocomplete (val) { 
      this.$emit('getDriverValue',val);
    },
  }
}
</script>