var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-select",
    {
      attrs: {
        filterable: "",
        clearable: _vm.clearable,
        "value-key": "id",
        placeholder: "请输入车牌号",
      },
      on: { clear: _vm.clearCph, change: _vm.handleAutocomplete },
      model: {
        value: _vm.vehicleName,
        callback: function ($$v) {
          _vm.vehicleName = $$v
        },
        expression: "vehicleName",
      },
    },
    _vm._l(_vm.cphList, function (single) {
      return _c("el-option", {
        key: single.id,
        attrs: { label: single.licensePlateNumber, value: single },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }