<template>
  <!-- 9.0调度-改班 -->
  <div class="change-shift">
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-position="right"
      class="demo-form dialog-form"
      label-width="120px"
    >
      <el-form-item label="线路：">
        <el-input v-model="form.lineName" disabled></el-input>
      </el-form-item>
      <el-form-item
        v-for="(element, index) in formList"
        :key="index"
        :label="element.label"
        :prop="element.props"
      >
        <template v-if="element.props === 'startDepartureDateTime'">
          <el-time-picker
            v-model="form[element.props]"
            format="HH:mm"
            value-format="HH:mm"
            placeholder="选择班次开始时间"
            @change="handleStartTime"
            :clearable="false"
            :disabled="isSoldDisbled"
          ></el-time-picker>
        </template>
        <template v-if="element.props === 'endDepartureDateTime'">
          <el-time-picker
            v-model="form[element.props]"
            format="HH:mm"
            value-format="HH:mm"
            :disabled="shiftIntercityObj.shiftType === 0 || isSoldDisbled"
            placeholder="选择班次结束时间"
            :clearable="false"
          ></el-time-picker>
        </template>
        <template v-if="element.props === 'status'">
          <el-select
            v-model="form[element.props]"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="role in statusList"
              :key="role.value"
              :label="role.label"
              :value="role.value"
            ></el-option>
          </el-select>
        </template>
        <template
          v-if="['ticketNum', 'reservedTicket'].includes(element.props)"
        >
          <!-- 锁票只能锁余票数。最大值 -->
          <!-- 票位数量回显，可随意更改 -->
          <el-input-number
            v-model="form[element.props]"
            :min="element.props === 'ticketNum' ? ticketNumMin : 0"
            :precision="0"
            :max="element.props === 'reservedTicket' ? lockTicketMax : 999"
          ></el-input-number>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {

  },
  props: {
    shiftIntercityObj: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    //开始时间小于等于结束时间
    const validateTimeForward = (rule, value, callback)=>{
      if(value && this.form.endDepartureDateTime){
        if(value > this.form.endDepartureDateTime ){
          callback(new Error('开始时间需要小于结束时间'))
        }else{
          callback();
        }
      }
    }
    //结束时间大于等于开始时间
    const validateTimeBackward = (rule, value, callback)=>{
      if(value && this.form.startDepartureDateTime){
        if(value >= this.form.startDepartureDateTime ){
          callback();
        }else{          
          callback(new Error('结束时间需要大于开始时间'))
        }
      }
    }
    return {
      form: {
        startDepartureDateTime: null,
        endDepartureDateTime: null,
        status:null,
        ticketNum:null,
        reservedTicket:0,        
      },
      lockTicketMax:0,
      formList: [
        {
          label: '开始时间：',
          props: "startDepartureDateTime"
        },
        {
          label: '结束时间：',
          props: "endDepartureDateTime"
        },
        {
          label: '班次状态：',
          props: "status"
        },
        {
          label: '票位数量：',
          props: "ticketNum"
        },
        {
          label: '锁票数量：',
          props: "reservedTicket"
        }
      ],
      statusList: [
        {
          label: "正常",
          value: 0
        },
        {
          label: "停售",
          value: 2
        },
      ],
      rules: {
        startDepartureDateTime: [
          { required: true, message: "请选择", trigger: "blur" },
          { validator:validateTimeForward, trigger: "change",
          },
        ],
        endDepartureDateTime: [
          { required: true, message: "请选择", trigger: "blur" },
          { validator:validateTimeBackward, trigger: "change",
          },
        ],
        status: [
          { required: true, message: "请选择", trigger: "blur" }
        ],
        reservedTicket: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
        ticketNum: [
          { required: true, message: "请输入", trigger: "blur" }
        ],
      },
      isSoldDisbled: false,//班次时间可否修改
      ticketNumMin:0,//票位数最小值
    }
  },
  created () {

  },
  mounted () {
    //已售票的的班次，时间不可修改-----班次余票和票位数相等就可以修改---优化停售的也能修改时间
    const { surplusTicket,ticketNum,soldTicket,reservedTicket,shiftIntercityStatus,startDepartureTime,endDepartureTime,lockTicketNum,lineName } = this.shiftIntercityObj;
    this.isSoldDisbled = shiftIntercityStatus === 2 || !(surplusTicket === ticketNum);
    this.form.ticketNum = ticketNum;//回显，可修改
    if(this.shiftIntercityObj?.driverIntercityOrders.length){
      let min = 0;
      this.shiftIntercityObj.driverIntercityOrders.map(item=>{
        min += item.vehicleCarryPassengerNumber;//最大载客数
      })
      this.ticketNumMin = min;
    }else{
      this.ticketNumMin = 0;
    }
    this.form.status = shiftIntercityStatus;
    this.form.reservedTicket = lockTicketNum;//回显，可修改覆盖原本保存的值
    this.form.startDepartureDateTime = startDepartureTime;
    this.form.endDepartureDateTime = endDepartureTime;
    this.lockTicketMax = ticketNum - soldTicket - reservedTicket;
    this.form.lineName = lineName;
  },
  methods: {
    handleStartTime (value) {
      if (this.shiftIntercityObj.shiftType === 0) {
        this.form.endDepartureDateTime = this.form.startDepartureDateTime;
      }
    },
    saveFun () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = {...this.form};
          //转成时间戳给后端
          let start =`${this.shiftIntercityObj.departureDate} ${this.form.startDepartureDateTime}`;
          let end =`${this.shiftIntercityObj.departureDate} ${this.form.endDepartureDateTime}`;         
          data.startDepartureDateTime = new Date(start).getTime();
          data.endDepartureDateTime = new Date(end).getTime();
          data.id = this.shiftIntercityObj.shiftIntercityId;
          delete data.lineName
          this.$emit("getFormResult",data);
        }
      })

      //还需要传班次id
    },
  },
  destroyed () {

  },
}
</script>
<style lang="scss" scoped>
/deep/.dialog-form.el-form .el-form-item .el-form-item__label{
  margin-top: 4px;
}
/deep/.el-input-number__decrease,
  /deep/.el-input-number__increase {
    height: 30px;
    line-height: 30px;
  }
</style>