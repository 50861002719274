<template>
  <div>
    <div style="margin: 0 30px;">
      <Table
        :table-data="tableData2"
        :title-name="titleName3"
        :table-height="300"
        :type-checkbox="true"
        @select-all="select"
      />
      <div class="feeBox">
        <div>
          <p>退票手续费(%)</p>
          <el-select
            v-model="serviceCharge"
            class="charterSelect"
            @change="refundChange"
          >
            <el-option
              v-for="item in serviceChargeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div>
          <p>退票金额</p>
          <el-input v-model="refundTotal" disabled />
        </div>
      </div>
      <div class="reasonBox">
        <p>原因</p>
        <el-input
          v-model.trim="ReasonForRefund"
          class="textareaInputs"
          type="textarea"
          placeholder="填写原因时请注意，该信息将以短信的形式发送给用户。"
        ></el-input>
      </div>
    </div>
    <div slot="footer" class="footer">
      <p class="desc">
        点击确认按钮提交退款申请后， 需等待当前弹窗消失， 期间请勿进行其它操作
      </p>
      <el-button @click="charterPopupClosePopupFun">取消</el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="charterPopupConfirmFun"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import {
  cancelTicket,
} from "@/api/lib/api.js";
export default {
  props:{
    tableDataRefund:{
      typeof:Array,
      default:()=>[]
    }
  },
  data () {
    return {
      ReasonForRefund: "",
      tableData2: [],
      titleName3: [
        {
          title: "票号",
          props: "id",
          width: "200",
        },
        {
          title: "乘客类型",
          props: "segment",
          SpecialJudgment: (res) => {
            return res == 0 ? "儿童" : "成人";
          },
        },
        {
          title: "乘客姓名",
          props: "passengerName",
        },
        {
          title: "身份证号码",
          width: 120,
          props: "idCardNo",
          SpecialJudgment: (res) => {
            return res == "匿名用户"
              ? res
              : this.decryptDes(res).replace(
                /^(.{4})(?:\d+)(.{3})$/,
                "$1******$2"
              );
          },
        },
        {
          title: "是否携童",
          props: "carryingChildren",
          SpecialJudgment: (res) => (res ? "是" : "否"),
        },
        {
          title: "票价",
          props: "ticketPricesPer",
        },
        {
          title: "服务费",
          props: "servicePricesPer",
        },
        {
          title: "支付金额",
          props: "totalPrices",
        },
        {
          title: "订单状态",
          props: "orderStatus",
          SpecialJudgment: (res) => {
            return this.childOrderStatusList.find((t) => t.value == res).label;
          },
        },
      ],
      childOrderStatusList: [
        { value: 20, label: "已支付" },
        { value: 90, label: "用户已退票" },
        { value: 95, label: "退款中" },
        { value: 100, label: "后台已退票" },
        { value: 130, label: "已改签" },
        { value: null, label: "" },
      ],
      loading: false,
      refundTotal: 0,
      serviceChargeList: [{ label: "0", value: 0 }],
      serviceCharge: 0,
      ticketPrice: 0,
      cancelTicketObj: {},
      children:0,
    }
  },
  mounted () {
    this.serviceChargeList =[{ label: "0", value: 0 }];
    this.recurSion(0);
    this.tableData2 = this.tableDataRefund;
  },
  methods: {
    select (selection) {
      this.ticketPrice = 0;
      this.cancelTicketObj.ids = [];
      this.children = 0;
      if (selection.length) {
        selection.forEach((e) => {
          this.ticketPrice +=
            e.preferentialType === 0 ? e.ticketPricesPer : e.totalPrices;
          this.cancelTicketObj.ids.push(e.id);
          this.children += e.carryingChildren;
        });
        this.refundTotal = this.serviceCharge
          ? ((this.ticketPrice * (100 - this.serviceCharge)) / 100).toFixed(2)
          : this.ticketPrice.toFixed(2);
      } else {
        this.ticketPrice = 0;
        this.refundTotal = 0;
      }
    },
    confirmOpera () {
      cancelTicket({
        orderIds: this.cancelTicketObj.ids,
        refundProportion: this.serviceCharge,
        reason: this.ReasonForRefund,
      }).then((res) => {
        if (res.code === "SUCCESS") {
          this.$message({
            type: "success",
            showClose: true,
            message: "退票成功!",
          });
          this.loading = false;
          this.charterPopupShow = false;
          this.ReasonForRefund = "";
          this.$emit('closeRefund')
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    charterPopupConfirmFun () {
      if (!this.refundTotal) {
        this.$message({
          type: "error",
          showClose: true,
          message: "请选择退票订单！",
        });
        return;
      }
      this.loading = true;
      if (this.children) {
        this.$confirm(`包含${this.children}张携童票，确认退订`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          center: true,
        }).then(() => {
          this.confirmOpera();
        });
      } else {
        this.confirmOpera();
      }
    },
    charterPopupClosePopupFun () {
      this.refundTotal = 0;
      this.ticketPrice = 0;
      this.serviceCharge = 0;
      this.charterPopupShow = false;
      this.cancelTicketObj = {};
      this.loading = false;
      this.$emit('closeRefund');
    },
    refundChange (val) {
      this.refundTotal = ((this.ticketPrice * (100 - val)) / 100).toFixed(2);
    },
    recurSion (num) {
      if (num < 90) {
        let Numb = (num += 5);
        this.serviceChargeList.push({
          label: Numb,
          value: Numb,
        });
        this.recurSion(Numb);
      } else {
        return;
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.feeBox {
  display: flex;
  margin: 40px 0;
  justify-content: space-between;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}

.reasonBox {
  margin: 40px 0;

  p {
    padding-bottom: 10px;
    font-size: 14px;
  }

  /deep/.el-input__inner {
    width: 300px;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  .desc {
    color: red;
    margin-right: 30px;
    font-size: 15px;
  }
}
</style>