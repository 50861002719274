<template>
  <!-- 9.0.1改派 -->
  <div
    class="shift-info-page"
    :class="{ 'ticket-change-page': isShowTicketChange }"
  >
    <div class="return" @click="returnBack">
      <i class="iconfont its_ba_fanhui"></i>
    </div>
    <div class="info-main">
      <p class="title">班次信息</p>
      <el-row class="row-style">
        <el-col :span="6">
          <span class="label">线路：</span>
          <span class="info">{{ shiftIntercityObj.lineName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="label">班次日期：</span>
          <span class="info">{{ shiftIntercityObj.departureDate }}</span>
        </el-col>
        <el-col :span="6">
          <span class="label">班次时间：</span>
          <span class="info">{{ computedTime() }}</span>
        </el-col>
      </el-row>
      <div class="title" v-if="itemData && itemData">
        <span>车次信息</span>
        <el-button
          type="text"
          size="small"
          sort="primary"
          class="operation-btn"
          @click="onCheck()"
          >查看车辆位置</el-button
        >
        <el-button
          :disabled="itemData.orderStatus == 40"
          type="text"
          size="small"
          sort="primary"
          class="operation-btn"
          @click="changeCarFun()"
          >改派</el-button
        >
        <el-button
          type="text"
          size="small"
          sort="danger"
          class="operation-btn red-bd"
          :disabled="computedStatusDele()"
          @click="deleteShift()"
          >取消派车</el-button
        >
      </div>
      <el-row class="row-style" v-if="itemData">
        <el-col :span="6">
          <span class="label">车次：</span>
          <span class="info">{{
            itemData.driverOrderAlias.substring(11, 16)
          }}</span>
        </el-col>
        <el-col :span="6">
          <span class="label">车牌号：</span>
          <span class="info">{{ itemData.licensePlateNumber }}</span>
        </el-col>
        <el-col :span="6">
          <span class="label">最大载客数：</span>
          <span class="info">{{ itemData.vehicleCarryPassengerNumber }}</span>
        </el-col>
        <el-col :span="6">
          <span class="label">驾驶员：</span>
          <span class="info"
            >{{ itemData.driverName }} ({{ itemData.driverPhone }})</span
          >
        </el-col>
      </el-row>
      <div class="title">
        <span>乘客信息</span>
        <el-button
          v-if="itemData"
          type="text"
          size="small"
          :disabled="
            !tableData.length || (itemData && itemData.orderStatus == 40)
          "
          class="operation-btn"
          @click="SMSFun()"
          >晚点通知</el-button
        >
      </div>
      <Table
        ref="Table"
        :table-data="tableData"
        :title-name="titleName"
        :operation="true"
        :table-height="420"
        operation-width="300"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            :disabled="computedStatus(scope.scopeRow)"
            size="small"
            sort="primary"
            @click="cancelSendOrders(scope.scopeRow)"
            >取消派单</el-button
          >
          <el-button
            :disabled="computedStatus(scope.scopeRow)"
            type="text"
            size="small"
            sort="primary"
            @click="cancelOrder(scope.scopeRow)"
            >取消接驾</el-button
          >
          <el-button
            v-if="!itemData"
            :disabled="scope.scopeRow.orderStatus != 20"
            type="text"
            size="small"
            sort="primary"
            @click="sendCarPassengerFun(scope.scopeRow)"
            >派车</el-button
          >
          <el-button
            v-if="
              !scope.scopeRow.refundStatus &&
              scope.scopeRow.orderStatus < 70 &&
              scope.scopeRow.orderStatus != 10 &&
              scope.scopeRow.purchaseMethod != 3 &&
              listFind('退票')
            "
            type="text"
            size="small"
            sort="primary"
            @click="cancelTicketFun(scope.scopeRow)"
            >退票</el-button
          >
          <el-button
            v-if="
              listFind('改签') &&
              scope.scopeRow.purchaseMethod != 3 &&
              [20, 30, 40].includes(scope.scopeRow.orderStatus)
            "
            type="text"
            size="small"
            sort="primary"
            @click="changeTicketFun(scope.scopeRow)"
            >改签</el-button
          >
        </template>
      </Table>
    </div>
    <!-- 查看车辆位置 -->
    <el-dialog
      v-if="dialogVisible2"
      title="查看车辆位置"
      :visible.sync="dialogVisible2"
      width="60%"
      append-to-body
      @close="dialogVisible2 = false"
    >
      <div>
        <view-map
          ref="viewMap"
          :map-driver-obj="mapDriverObj"
          :driver-order-id="itemData.id"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="dialogVisible2 = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="SMStitle"
      :visible.sync="dialogVisible1"
      v-if="dialogVisible1"
      width="500px"
      append-to-body
      @close="onDialogClose1"
    >
      <div class="body">
        <el-form
          ref="addForm"
          :model="addForm"
          :rules="rules"
          label-width="0px"
          label-position="right"
          class="demo-form dialog-form"
        >
          <el-form-item v-if="SMStitle == '取消接驾'" label="">
            <div class="tips">
              <i class="el-icon-warning"></i>取消接驾后订单将无法检票！
            </div>
          </el-form-item>
          <el-form-item label="" prop="name">
            <el-input
              v-model.trim="addForm.name"
              type="textarea"
              maxlength="20"
              placeholder="填写原因时请注意，该信息将以短信的形式发送给用户。"
              class="textareaInputs"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="addForm.name && SMStitle == '晚点短信群发'"
            label=""
          >
            <div class="SMSpreview">
              <!-- {{
                `班次：${filterDepartureDate(
                  shiftIntercityObj.departureDate
                )} ${
                  shiftIntercityObj.shiftType === 0
                    ? shiftIntercityObj.startDepartureTime
                    : shiftIntercityObj.startDepartureTime +
                      "-" +
                      shiftIntercityObj.endDepartureTime
                }，${shiftIntercityObj.startStation}-${
                  shiftIntercityObj.endStation
                }因故晚点，原因：${addForm.name}。电话：${
                  itemData.driverPhone
                }。`
              }} -->
              {{
                `您预订的城际专车，时间：${shiftIntercityObj.departureDate}
              ${
                shiftIntercityObj.shiftType === 0
                  ? shiftIntercityObj.startDepartureTime
                  : shiftIntercityObj.startDepartureTime +
                    "-" +
                    shiftIntercityObj.endDepartureTime
              }，线路：${shiftIntercityObj.startStation}-${
                  shiftIntercityObj.endStation
                }因故晚点，原因：${
                  addForm.name
                }。由此对您造成的不便，敬请谅解。如有疑问，请联系司机：${
                  itemData.driverName
                }，电话：${itemData.driverPhone}。`
              }}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose1"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="small"
          :loading="editLoading"
          @click="cancelOrderSave"
        >
          {{ SMStitle == "晚点短信群发" ? "发送" : "保存" }}
        </el-button>
      </span>
    </el-dialog>
    <!-- 改派 -->
    <el-dialog
      title="改派"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="500px"
      append-to-body
      @close="onDialogClose"
    >
      <el-form
        ref="changeForm"
        :model="changeForm"
        :rules="changeRules"
        label-width="90px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="乘客人数：">
          <el-input v-model="changeForm.passengerCount" disabled />
        </el-form-item>
        <el-form-item label="改派车辆：" prop="vehicleId">
          <cphSelectForSearch
            :lineId="shiftIntercityObj.lineId"
            @getVehiceValue="getVehiceId"
          ></cphSelectForSearch>
          <div v-if="isShowTips()" class="tipsoverflow">
            <i class="el-icon-warning"></i>车辆载客数小于乘客数，请重新选择！
          </div>
        </el-form-item>
        <el-form-item label="载客数：">
          <el-input
            v-model="changeForm.seatNumber"
            disabled
            placeholder="请先选择车辆"
          />
        </el-form-item>
        <el-form-item label="改派司机：" prop="driverId">
          <driverSelectForSearch
            ref="driverSelectForSearch"
            :licensePlateNumber="changeForm.licensePlateNumber"
            @getDriverValue="getDriverId"
            :singleChoose="true"
          ></driverSelectForSearch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="onDialogClose"
          >取消</el-button
        >
        <el-button
          v-if="itemData.orderStatus != 40"
          type="primary"
          size="small"
          :loading="editLoading"
          :disabled="isShowTips()"
          @click="handleChangeCendCar"
        >
          确认改派
        </el-button>
      </span>
    </el-dialog>
    <!-- 退票 -->
    <el-dialog
      v-if="charterPopupShow"
      :visible.sync="charterPopupShow"
      width="800px"
      title="退票"
      append-to-body
      @close="charterPopupShow = false"
    >
      <refund-ticket-select
        :tableDataRefund="tableDataRefund"
        @closeRefund="closeRefund"
      ></refund-ticket-select>
    </el-dialog>
    <!-- v7.1改签页面 -->
    <change-ticket
      v-if="isShowTicketChange"
      :ticketChangeForm="ticketChangeForm"
      @close="closeChangeTicket"
    ></change-ticket>
  </div>
</template>
<script>
import {
  getVehicleList,
  driverIntercityPageAPI,
  cancelOrder,
  lateNoticeAPI,
  cancelTicket,
  referOrderDetialAPI,
  getOrderInfoAPI,
} from "@/api/lib/api.js";
import {
  getPassengerOrdersAPI,
  cancelDispatchPassengersAPI,
  byShiftIntercityIdAPI, //查看班次乘客
} from "@/api/lib/remouldAPI.js";
import moment from "moment";
import viewMap from "../viewMap/viewMap.vue";
import cphSelectForSearch from "@/components/treeSelect/cphSelectForSearch.vue";
import driverSelectForSearch from "@/components/treeSelect/driverSelectForSearch.vue";
import RefundTicketSelect from "./refundTicketSelect.vue";
import ChangeTicket from "./changeTicket.vue";
export default {
  components: {
    viewMap,
    cphSelectForSearch,
    driverSelectForSearch,
    RefundTicketSelect,
    ChangeTicket,
  },
  props: {
    //车次信息
    itemData: {
      type: Object,
      default: () => {},
    },
    //班次信息
    shiftIntercityObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const validateDriverRequire = (rule, value, callback) => {
      if (this.changeForm.vehicleId) {
        if (!value) {
          callback(new Error("请选择驾驶员"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      tableData: [],
      titleName: [
        {
          title: "出行时间",
          SpecialJudgment: (res) => {
            let str = res.startAnticipationRidingTime?.substring(10, 16);
            if (res.shiftType === 1) {
              str += "-" + res.endAnticipationRidingTime?.substring(10, 16);
            }
            return str;
          },
        },
        {
          title: "下单人",
          props: "passengerPhone",
        },
        {
          title: "乘车人",
          props: "orderPhone",
        },
        {
          title: "乘客数",
          props: "validPassengersNum",
        },
        {
          title: "乘车码",
          props: "passengerCertificate",
        },
        {
          title: "上车点",
          props: "startAddress",
          SpecialJudgment: (res) => {
            return res.split("|")[0];
          },
        },
        {
          title: "下车点",
          props: "endAddress",
          SpecialJudgment: (res) => {
            return res.split("|")[0];
          },
        },
        // {
        //   title: "状态",
        //   props: "orderStatus",
        //   SpecialJudgment: res => {
        //     let text = "";
        //     switch (res) {
        //       case 20:
        //         text = "已支付(待配车)";
        //         break;
        //       case 30:
        //         text = "已配车(待派单)";
        //         break;
        //       case 40:
        //         text = "已派单(待接客)"
        //         break;
        //       case 60:
        //         text = "已上车";
        //         break;
        //       case 70:
        //         text = "已完成";
        //         break;
        //     }
        //     return text
        //   }
        // },
      ],
      mapDriverObj: null,
      dialogVisible1: false,
      dialogVisible2: false,
      SMStitle: "",
      addForm: {
        name: "",
        intercityOrderId: "",
      },
      rules: {
        name: [{ required: true, message: "请输入原因", trigger: "blur" }],
        ticketPrice: [
          { required: true, message: "请输入必填项", trigger: "blur" },
        ],
      },
      editLoading: false,
      dialogVisible: false,
      changeForm: {
        passengerCount: null,
        vehicleId: null,
        licensePlateNumber: "",
        seatNumber: null,
        driverId: null,
      },
      changeRules: {
        vehicleId: [{ required: true, message: "请选择车辆", trigger: "blur" }],
        driverId: [
          { required: true, message: "请选择司机", trigger: "blur" },
          {
            validator: validateDriverRequire,
            message: "请选择驾驶员",
            trigger: "blur",
          },
        ],
      },
      tableDataRefund: [],
      charterPopupShow: false, //退票
      ticketChangeForm: {},
      isShowTicketChange: false, //改签
    };
  },

  mounted() {
    this.queryPassengerList();
  },
  methods: {
    filterDepartureDate(val) {
      return moment(val).format("MM月DD日");
    },
    // 已完成且订单数为0，可删除车次
    computedStatusDele() {
      if (this.itemData.orderStatus === 40) {
        return this.tableData.length > 0;
      } else {
        return this.itemData.orderStatus > 20;
      }
    },
    closeChangeTicket() {
      this.isShowTicketChange = false;
      this.queryPassengerList();
    },
    closeRefund() {
      this.charterPopupShow = false;
      this.queryPassengerList();
    },
    computedStatus(row) {
      if (this.itemData) {
        const { orderStatus } = this.itemData;
        /**
         * 行程中状态车次，已检票的乘客的取消派单/取消接驾按钮需不可点击；20 < status < 40 >60
         * 已完成状态车次，取消派单/取消接驾按钮需不可点击 40
         */
        return (
          orderStatus == 40 ||
          (20 < orderStatus && orderStatus < 40 && row.orderStatus >= 60)
        );
      } else {
        return [20, 30, 60, 70].includes(row.orderStatus);
      }
    },
    closeLoading() {
      this.dialogVisible = false;
      this.editLoading = false;
    },
    handleChangeCendCar() {
      if (this.itemData.orderStatus === 40) {
        this.$message.error("已完成的车次不可改派！");
        return;
      }
      this.saveFun();
    },
    saveFun() {
      this.$refs.changeForm.validate((valid) => {
        if (valid) {
          let ids = this.tableData.map((item) => {
            return item.id;
          });
          const data = {
            shiftIntercityId: this.itemData.shiftIntercityId,
            driverOrderId: this.itemData.id,
            vehicleId: this.changeForm.vehicleId,
            driverId: this.changeForm.driverId,
            intercityOrderIds: ids,
          };
          this.editLoading = true;
          this.$emit("getFormResult", data);
        }
      });
    },
    getVehiceId(val, name, num) {
      this.changeForm.vehicleId = val;
      this.changeForm.licensePlateNumber = name;
      this.changeForm.seatNumber = num;
      this.changeForm.driverId = null;
      //必须加nextTick,否则组件调用时监听不到licensePlateNumber
      this.$nextTick(() => {
        this.$refs.driverSelectForSearch.clearDriver();
      });
    },
    getDriverId(val) {
      this.changeForm.driverId = val;
    },
    isShowTips() {
      //若改派的车辆载客数小于乘客数，在车牌号的选择框下方显示提示信息
      return (
        this.changeForm.vehicleId &&
        this.changeForm.seatNumber < this.changeForm.passengerCount
      );
    },
    changeCarFun() {
      this.changeForm.passengerCount =
        this.itemData.vehicleCarryPassengerNumber -
        this.itemData.surplusCarryPassengerNumber;
      this.dialogVisible = true;
    },
    onDialogClose() {
      // this.$refs.changeForm.clearValidate();
      this.changeForm = {
        passengerCount: null,
        vehicleId: null,
        licensePlateNumber: "",
        seatNumber: null,
        driverId: null,
      };
      this.dialogVisible = false;
    },
    // 退票
    cancelTicketFun(row) {
      referOrderDetialAPI(row.id).then((res) => {
        if (res.code === "SUCCESS") {
          if (row.totalPrices == 0) {
            this.$confirm("确认退票？", "提示", {
              center: true,
            }).then(() => {
              const obj = {
                orderIds: res.data.map((i) => i.id),
                refundProportion: 0,
                reason: "取消订单",
              };
              cancelTicket(obj).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("退票成功");
                  this.queryPassengerList();
                }
              });
            });
          } else {
            this.tableDataRefund = res.data;
            this.charterPopupShow = true;
          }
        }
      });
    },
    changeTicketFun(row) {
      if (row.orderPayStatus === 0) {
        this.$router.push({
          name: "telebookingNew",
          params: { id: row.id, fromPage: "showPassenger" },
        });
        return;
      }
      this.$route.meta.title = "改签";
      getOrderInfoAPI({ orderId: row.id }).then((res) => {
        if (res.code === "SUCCESS") {
          this.ticketChangeForm = res.data;
          this.isShowTicketChange = true;
        }
      });
    },
    //乘客派车
    sendCarPassengerFun(row) {
      this.$emit("sendPassenger", row);
    },
    // 取消接驾
    cancelOrder(row) {
      this.addForm.intercityOrderId = row.id;
      this.SMStitle = "取消接驾";
      this.dialogVisible1 = true;
    },
    //取消派单
    cancelSendOrders(row) {
      this.$confirm(
        "取消派单后，当前订单进入待派单列表，系统不再自动分配该订单，需手动分配。",
        "提示"
      )
        .then(() => {
          const data = {
            driverOrderId: row.driverOrderId,
            orderIds: [row.id],
          };
          cancelDispatchPassengersAPI(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success("取消派单成功");
              this.queryPassengerList();
            }
          });
        })
        .catch(() => {});
    },
    // 取消接驾确认
    cancelOrderSave() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.SMStitle == "取消接驾") {
            cancelOrder({
              intercityOrderId: this.addForm.intercityOrderId,
              orderAdditionalInformation: this.addForm.name,
            }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("取消接驾成功");
                this.dialogVisible1 = false;
                this.queryPassengerList();
              }
            });
          } else if (this.SMStitle == "晚点短信群发") {
            lateNoticeAPI({
              driverOrderId: this.itemData.id,
              orderAdditionalInformation: this.addForm.name,
            }).then((res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("发送成功");
                this.dialogVisible1 = false;
              }
            });
          }
        }
      });
    },
    // 弹窗关闭时的回调
    onDialogClose1() {
      this.addForm = {
        name: "",
      };
      this.dialogVisible1 = false;
      this.editLoading = false;
    },
    // 点击短信群发按钮
    SMSFun() {
      this.SMStitle = "晚点短信群发";
      this.dialogVisible1 = true;
    },
    //查看车辆位置
    onCheck() {
      this.dialogVisible2 = true;
      this.mapDriverObj = {
        driverId: this.itemData.driverId,
        licensePlateColorStr: this.itemData.licensePlateColorStr,
        licensePlateNumber: this.itemData.licensePlateNumber,
      };
    },
    queryPassengerList() {
      let queryData = null;
      let queryPath = null;
      if (this.itemData) {
        queryData = {
          driverOrderId: this.itemData.id,
        };
        queryPath = getPassengerOrdersAPI;
      } else {
        /**
         * 订单状态 10:待支付 20:已支付(待配车) 30:已配车(待派单) 40:已派单(待接客) 50:司机申请取消接客 60:已接客 70:已完成 80:取消接客 90:用户已退票 95:退款中 100:后台已退票 110:订单取消 120:订单删除
         */
        queryData = {
          shiftIntercityId: this.shiftIntercityObj.shiftIntercityId,
          orderStatus: "20,30,40,60,70",
        };
        queryPath = byShiftIntercityIdAPI;
      }
      queryPath(queryData).then((res) => {
        if (res.code === "SUCCESS") {
          this.tableData = res.data || [];
        }
      });
    },
    deleteShift() {
      //没有乘客则是二次确认即可
      this.$emit("deleteShift", this.itemData, this.tableData.length === 0);
    },
    computedTime() {
      let str = this.shiftIntercityObj.startDepartureTime;
      if (this.shiftIntercityObj.shiftType === 1) {
        str += `-${this.shiftIntercityObj.endDepartureTime}`;
      }
      return str;
    },
    returnBack() {
      this.$emit("backClose");
    },
  },
};
</script>
<style lang="scss" scoped>
.shift-info-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  background: #f7f8f9;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  box-sizing: border-box;
  .ticket-change-page {
    margin-top: 50px;
    position: relative;
    height: calc(100% - 32px - 50px) !important;
  }
  .return {
    width: 32px;
    height: 32px;
    color: #333333;
    font-size: 15px;
    background-color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 3px 1px rgba(52, 52, 52, 0.1);
  }
  i {
    cursor: pointer;
  }
  .info-main {
    width: 100%;
    height: calc(100% - 42px);
    box-sizing: border-box;
    background: #ffffff;
    margin-top: 10px;
    overflow-y: auto;
    padding: 20px 20px 0;
    .title {
      padding: 0 21px;
      line-height: 46px;
      height: 46px;
      font-weight: bold;
      background-color: #f7f8f9;
      border-left: #336ffe solid 2px;
    }
    .operation-btn {
      margin-left: 36px;
      padding-left: 10px;
      padding-right: 10px;
      border-color: #336ffe;
    }
    .red-bd {
      border-color: #d40000;
    }
    .row-style {
      padding: 16px 20px;
      .label {
        color: #828485;
        margin-right: 6px;
      }
    }
  }
}
/deep/.Table {
  margin-top: 20px;
}

.textareaInputs {
  width: 100%;

  /deep/ textarea {
    width: 100% !important;
    height: 60px !important;
    resize: none !important;
  }
}

.SMSpreview {
  background: #ffffff;
  padding: 5px 15px;
  border: 1px solid #d3d6dd;
  border-radius: 6px;
  line-height: 20px;
}
.tips {
  color: #666666;
  font-size: 14px;
  background-color: #e4ecff;
  border-radius: 6px;
  padding: 0 16px;
  box-sizing: border-box;
  margin-bottom: -10px;
}
.el-icon-warning {
  color: #336ffe;
  font-size: 16px;
  margin-right: 6px;
}
/deep/ .dialog-form {
  padding: 1.5vh 1.5vw;
  .el-form-item .el-form-item__label {
    margin-top: 4px;
  }
}
.tipsoverflow {
  color: #ff0031;
  margin-bottom: -16px;
  .el-icon-warning {
    color: #ff0031;
  }
}
</style>
