var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "send-car" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "demo-form dialog-form",
          attrs: {
            model: _vm.form,
            "label-position": "right",
            "label-width": "80px",
          },
        },
        [
          _vm._l(_vm.form.dispatchDriverVehicles, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "row-style" },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "车次：",
                      prop:
                        "dispatchDriverVehicles." + index + ".driverOrderAlias",
                      rules: {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择",
                          disabled: _vm.shiftIntercityObj.shiftType === 0,
                        },
                        model: {
                          value: item.driverOrderAlias,
                          callback: function ($$v) {
                            _vm.$set(item, "driverOrderAlias", $$v)
                          },
                          expression: "item.driverOrderAlias",
                        },
                      },
                      _vm._l(_vm.timeOptions, function (single) {
                        return _c("el-option", {
                          key: single,
                          attrs: { label: single, value: single },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "车牌号：",
                      prop:
                        "dispatchDriverVehicles." +
                        index +
                        ".licensePlateNumber",
                      rules: {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          remote: "",
                          "reserve-keyword": "",
                          clearable: "",
                          "value-key": "id",
                          placeholder: "请输入车牌号",
                          "remote-method": (val) => {
                            _vm.queryCph(val, index)
                          },
                        },
                        on: {
                          clear: function ($event) {
                            return _vm.clearCph(index)
                          },
                          change: function ($event) {
                            return _vm.handleAutocomplete($event, index)
                          },
                        },
                        model: {
                          value: item.licensePlateNumber,
                          callback: function ($$v) {
                            _vm.$set(item, "licensePlateNumber", $$v)
                          },
                          expression: "item.licensePlateNumber",
                        },
                      },
                      _vm._l(item.cphList, function (single) {
                        return _c("el-option", {
                          key: single.id,
                          attrs: {
                            label: single.licensePlateNumber,
                            value: single,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "载客数：" } },
                  [
                    _c("el-input", {
                      attrs: { disabled: "", placeholder: "请先选择车辆" },
                      model: {
                        value: item.seatNumber,
                        callback: function ($$v) {
                          _vm.$set(
                            item,
                            "seatNumber",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "item.seatNumber",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "驾驶员：",
                      prop: "dispatchDriverVehicles." + index + ".driverId",
                      rules: {
                        required: true,
                        message: "请选择",
                        trigger: "blur",
                      },
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          filterable: "",
                          remote: "",
                          "reserve-keyword": "",
                          clearable: "",
                          placeholder: "请输入驾驶员名称",
                          "remote-method": (query) => {
                            _vm.queryDriver(query, index)
                          },
                        },
                        on: {
                          clear: function ($event) {
                            return _vm.resetList(index)
                          },
                        },
                        model: {
                          value: item.driverId,
                          callback: function ($$v) {
                            _vm.$set(item, "driverId", $$v)
                          },
                          expression: "item.driverId",
                        },
                      },
                      _vm._l(item.driverList, function (single) {
                        return _c("el-option", {
                          key: single.driverId,
                          attrs: {
                            label: single.driverName,
                            value: single.driverId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c("el-form-item", [
                  _vm.form.dispatchDriverVehicles.length > 1
                    ? _c("i", {
                        staticClass: "iconfont its_gy_delete",
                        on: {
                          click: function ($event) {
                            return _vm.deleteVisitor(index)
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ],
              1
            )
          }),
          _c(
            "el-form-item",
            { attrs: { "label-width": "0px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "text", sort: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.onAdd()
                    },
                  },
                },
                [_vm._v("继续添加车次")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }