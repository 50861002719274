<template>
  <!-- 9.0待派单乘客派车弹窗 -->
  <div class="send-car">
    <div class="tips">
      <i class="el-icon-warning"></i>若乘客订单派给已完成车次，乘客订单状态自动变更为已完成！
    </div>
    <h3>订单所属班次</h3>
    <div class="tips otherTips" v-if="!tableiIncludeData.length"><i class="el-icon-warning"></i>请先添加车次并指派驾驶员和车辆！</div>
    <Table
      v-else
      ref="includeTable"
      :table-data="tableiIncludeData"
      :title-name="titleName"
      :type-checkbox="true"
      :table-height="150"
      :isSingleChecked="true"
      :sendCar="true"
      :onOff="false"
      @select-all="selectCarInclude"
      :validPassengersNum="passengerObj.validPassengersNum"
    >
    </Table>
    <h3>其他班次</h3>
    <Table
      ref="Table"
      :table-data="tableData"
      :title-name="titleName"
      :type-checkbox="true"
      :table-height="300"
      :isSingleChecked="true"
      :sendCar="true"
      :onOff="false"
      @select-all="selectCar"
      :validPassengersNum="passengerObj.validPassengersNum"
    >
    </Table>
  </div>
</template>
<script>
import {
  getLineDriverOrdersAPI,
} from "@/api/lib/remouldAPI.js";
export default {
  components: {

  },
  props: {
    passengerObj: {
      type: Object,
      default: () => { }
    },
    departureDate: {
      type: String,
      default: ""
    },
  },
  data () {
    return {
      tableData: [],//其他班次
      tableiIncludeData:[],//订单所属班次
      titleName: [
        {
          title: "班次",
          SpecialJudgment: (res) => {
            let timeStr = res.startDepartureTime;
            //定班只显示开始时间
            if (res.shiftType === 1) {
              timeStr += '-' + res.endDepartureTime;
            }
            return timeStr;
          },
        },
        {
          title: "车次",
          props: "driverOrderAlias",
          SpecialJudgment: res => {
            return res?.substring(11, 16)
          }
        },
        {
          title: "车次状态",
          props: "orderStatus",
          SpecialJudgment: status => {
            let statusText = "";
            if (status === 40) {
              statusText = "已完成";
            } else if (20 < status && status < 40) {
              statusText = "行程中";
            } else if (status <= 20) {
              statusText = "待发车";
            }
            return statusText;
          }
        },
        {
          title: "车牌号",
          props: "licensePlateNumber",
        },
        {
          title: "驾驶员",
          props: "driverName",
        },
        {
          title: "剩余票位",
          props: "surplusCarryPassengerNumber",
        },
      ],
      selectCarData: null,
    }
  },
  created () {

  },
  mounted () {
    this.queryDriverList();
  },
  methods: {
    queryDriverList () {      
      getLineDriverOrdersAPI({ lineId: this.passengerObj.lineId, departureDate: this.departureDate }).then(res => {
        if (res.code === "SUCCESS") {          
          this.tableData = res.data.filter(element => {
            return element.shiftIntercityId != this.passengerObj.shiftIntercityId
          });
          this.tableiIncludeData = res.data.filter(element => {
            return element.shiftIntercityId === this.passengerObj.shiftIntercityId
          });
        }
      })
    },
    /**
     * 选择之前，先清空其他表格选中状态
     */
    selectCar (select) {
      this.$refs?.includeTable?.$refs.Table.clearSelection();
      this.$nextTick(()=>{
        this.selectCarData = select.length ? select[0] : null;//之前单选有bug，兼容之前的所以返回的数据
      })      
    },
    selectCarInclude (select) {
      this.$refs?.Table?.$refs.Table.clearSelection();
      this.$nextTick(()=>{
        this.selectCarData = select.length ? select[0] : null;//之前单选有bug，兼容之前的所以返回的数据
      })      
    },
    saveFun () {
      if (!this.selectCarData) {
        this.$emit("close");
        return
      }
      const data = {
        driverOrderId: this.selectCarData?.id,
        intercityOrderIds: [this.passengerObj.id]
      }
      if (this.selectCarData.shiftIntercityId != this.passengerObj.shiftIntercityId) {
        this.$confirm("当前为跨班次派车，会占用该班次票位，原班次票位会释放。", "提示")
          .then(() => {
            this.$emit('save', data)
          })
          .catch(() => { });
      } else {
        this.$emit('save', data)
      }
    },
  },
  destroyed () {

  },
}
</script>
<style lang="scss" scoped>
.tips {
  color: #666666;
  font-size: 14px;
  background-color: #e4ecff;
  border-radius: 6px;
  padding: 12px 16px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.el-icon-warning {
  color: #336ffe;
  font-size: 16px;
  margin-right: 6px;
}
.otherTips{
  background: rgba(247, 208, 181, 0.2);
  .el-icon-warning{
  color: #999;
}
} 
</style>