<template>
  <!-- 9.0调度派车弹窗 -->
  <div class="send-car">
    <el-form
      ref="form"
      :model="form"
      label-position="right"
      class="demo-form dialog-form"
      label-width="80px"
    >
      <template>
        <div
          v-for="(item, index) in form.dispatchDriverVehicles"
          :key="index"
          class="row-style"
        >
          <el-form-item
            label="车次："
            :prop="'dispatchDriverVehicles.' + index + '.driverOrderAlias'"
            :rules="{
              required: true,
              message: '请选择',
              trigger: 'blur',
            }"
          >
            <!-- <el-time-select
              v-model="item.driverOrderAlias"
              placeholder="选择车次"
              :disabled="shiftIntercityObj.shiftType === 0"
              :picker-options="pickerPosition"
              :clearable="false"
            ></el-time-select> -->
            <el-select
              v-model="item.driverOrderAlias"
              placeholder="请选择"
              :disabled="shiftIntercityObj.shiftType === 0"
            >
              <el-option
                v-for="single in timeOptions"
                :key="single"
                :label="single"
                :value="single"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="车牌号："
            :prop="'dispatchDriverVehicles.' + index + '.licensePlateNumber'"
            :rules="{
              required: true,
              message: '请选择',
              trigger: 'blur',
            }"
          >
            <el-select
              v-model="item.licensePlateNumber"
              filterable
              remote
              reserve-keyword
              clearable
              value-key="id"
              placeholder="请输入车牌号"
              :remote-method="
                (val) => {
                  queryCph(val, index)
                }
              "
              @clear="clearCph(index)"
              @change="handleAutocomplete($event, index)"
            >
              <el-option
                v-for="single in item.cphList"
                :key="single.id"
                :label="single.licensePlateNumber"
                :value="single"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="载客数：">
            <el-input
              v-model.trim="item.seatNumber"
              disabled
              placeholder="请先选择车辆"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="驾驶员："
            :prop="'dispatchDriverVehicles.' + index + '.driverId'"
            :rules="{
              required: true,
              message: '请选择',
              trigger: 'blur',
            }"
          >
            <el-select
              v-model="item.driverId"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请输入驾驶员名称"
              :remote-method="
                (query) => {
                  queryDriver(query, index)
                }
              "
              @clear="resetList(index)"
            >
              <el-option
                v-for="single in item.driverList"
                :key="single.driverId"
                :label="single.driverName"
                :value="single.driverId"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <i
              v-if="form.dispatchDriverVehicles.length > 1"
              class="iconfont its_gy_delete"
              @click="deleteVisitor(index)"
            ></i>
          </el-form-item>
        </div>
      </template>
      <el-form-item label-width="0px">
        <el-button type="text" sort="primary" @click="onAdd()"
          >继续添加车次</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  getVehicleList,
  driverIntercityPageAPI,
} from "@/api/lib/api.js";
export default {
  components: {},
  props: {
    shiftIntercityObj: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      form: {
        dispatchDriverVehicles: [
          {
            driverOrderAlias: this.currentTime,
            vehicleId: null,
            seatNumber: null,
            driverId: null,
            licensePlateNumber: "",
            driverList: [],
            cphList:[],
          }
        ]
      },
      currentTime: "",
      pickerPosition: {},
      timeOptions: [],
    }
  },
  created () {
    this.setCurrentTime();
  },
  mounted () {
    this.form.dispatchDriverVehicles[0].driverOrderAlias = this.currentTime;
    const { departureDate, startDepartureTime, endDepartureTime } = this.shiftIntercityObj;
    this.pickerPosition = {
      start: this.computedStartEndTime(departureDate, startDepartureTime),
      end: this.computedStartEndTime(departureDate, endDepartureTime),
      step: "00:10"
    };
    this.generateHalfHourSegments([
      `${startDepartureTime}-${endDepartureTime}`,
    ]);
    this.queryCph(null,0);
  },
  methods: {
    generateHalfHourSegments (openTime) {
      let y = new Date().getFullYear();
      let m = new Date().getMonth() + 1;
      let d = new Date().getDate();
      let start = [],
        end = []; //start起始时间数组，end结束时间数组
      for (let i = 0, len = openTime.length; i < len; i++) {
        //将时间字符串转换成日期格式并存入开始时间数组和结束时间数组
        let [s, e] = openTime[i].split("-");
        start.push(new Date(y + "/" + m + "/" + d + " " + s));
        end.push(new Date(y + "/" + m + "/" + d + " " + e));
      }
      let list = [];
      function formatTime (time) {
        //时间为个位数则补零
        return time < 10 ? "0" + time : time;
      }
      for (let i = 0, len = start.length; i < len; i++) {
        let len2 = (end[i].getTime() - start[i].getTime()) / (10 * 60 * 1000); //计算各子区间以半小时为间隔拆分后的数组长度
        for (let j = 0; j <= len2; j++) {
          if (start[i].getTime() + 10 * 60 * 1000 <= end[i].getTime()) {
            //将各子区间日期按半小时递增转换为时间并存入list数组
            let ss = new Date(start[i].getTime() + 10 * 60 * 1000 * j);
            list.push(
              formatTime(ss.getHours()) + ":" + formatTime(ss.getMinutes())
            );
          }
        }
      }
      if (
        new Date(
          y + "/" + m + "/" + d + " " + list[list.length - 1]
        ).getTime() -
        end[0].getTime() <
        0
      ) {
        list.push(openTime[0].split("-")[1]);
      }
      // console.log(list);
      this.timeOptions = list;
    },

    //定班时间区间和限制
    computedStartEndTime (departureDate, departureTime) {
      const singleDate = new Date(`${departureDate} ${departureTime}`);
      return this.queryTime(singleDate)
    },
    queryTime (parameter) {
      return this.formatTime(parameter.getHours(), parameter.getMinutes());
    },
    // 删除乘客
    deleteVisitor (index) {
      this.form.dispatchDriverVehicles.splice(index, 1);
    },
    onAdd () {
      this.setCurrentTime();
      this.form.dispatchDriverVehicles.push({
        driverOrderAlias: this.currentTime,
        vehicleId: null,
        seatNumber: null,
        driverId: null,
        licensePlateNumber: "",
        driverList: [],
        cphList:[],
      });
      this.queryCph(null,this.form.dispatchDriverVehicles.length - 1);
    },
    clearCph (index) {
      //车牌号及驾驶员都清空
      this.form.dispatchDriverVehicles[index].licensePlateNumber = "";
      this.form.dispatchDriverVehicles[index].vehicleId = null;
      this.form.dispatchDriverVehicles[index].driverId = null;
      this.form.dispatchDriverVehicles[index].seatNumber = null;
      this.form.dispatchDriverVehicles[index].driverList = [];
      this.queryCph(null,index);
    },
    queryDriver (query, index) {
      if (query) {
        setTimeout(() => {
          const data = {
            driverName: query,
            currentPage: 1,
            pageSize: 999,
            licensePlateNumber: this.form.dispatchDriverVehicles[index].licensePlateNumber.licensePlateNumber,
            driveType:[0]
          };
          driverIntercityPageAPI(data).then((res) => {
            if (res.code === "SUCCESS") {
              this.form.dispatchDriverVehicles[index].driverList = res.data.list;
            }
          });
        }, 200);
      } else{
        this.resetList(index);
      }
    },
    resetList (index) {
      if(this.form.dispatchDriverVehicles[index].licensePlateNumber.licensePlateNumber){
        this.driverRelation(this.form.dispatchDriverVehicles[index].licensePlateNumber.licensePlateNumber, index);
      }      
    },
    // 人车关系
    driverRelation (licensePlateNumber, index) {
      const queryData = {
        licensePlateNumber: licensePlateNumber,
        currentPage: 1,
        pageSize: 999,
        driveType:[0]
      }
      driverIntercityPageAPI(queryData).then((res) => {
        if (res.code == "SUCCESS") {
          this.form.dispatchDriverVehicles[index].driverList = res.data.list;
          this.$refs.form.clearValidate();
          if (res.data.list?.length === 1) {
            this.form.dispatchDriverVehicles[index].driverId = res.data.list[0].driverId;
          }
        }
      });
    },
    saveFun () {
      //提交的时候需要把车次替换成这种格式2024-08-03 12:00  
      const data = this.deepClone(this.form);
      data.dispatchDriverVehicles.forEach(element => {
        //此处需要确保无论验证没过修改哪的值，最终提交到后台的数据里面车次格式都是“日期+时分”不会多次拼接日期
        let dateSingle = element.driverOrderAlias;
        let tempDate = null;
        if (dateSingle.length > 5) {
          tempDate = dateSingle;
        } else {
          tempDate = `${this.shiftIntercityObj.departureDate} ${element.driverOrderAlias}`
        }
        element.driverOrderAlias = tempDate;
        element.shiftIntercityId = this.shiftIntercityObj.shiftIntercityId;
        element.licensePlateNumber = element.licensePlateNumber.licensePlateNumber;
        delete element.driverList
        delete element.cphList
      });
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$nextTick(() => {
            this.$emit('getFormResult', data);
          })
        }
      })
    },
    handleAutocomplete (item, index) {
      this.form.dispatchDriverVehicles[index].vehicleId = item.id;
      this.form.dispatchDriverVehicles[index].seatNumber = item.seatNumber ? item.seatNumber - 1 : 0;
      this.form.dispatchDriverVehicles[index].driverId = null;
      item && this.driverRelation(item.licensePlateNumber, index);
    },
    /**
     * @description 绑定的车辆-车牌号模糊查询
     */
    querySearchAsync (queryString, cb) {
      if (queryString != "" && queryString != " ") {
        let cphArray = [];
        getVehicleList({
          licensePlateNumber: queryString, currentPage: 1,
          pageSize: 999, lineIds: [this.shiftIntercityObj.lineId]
        }).then((res) => {
          if (res.code == "SUCCESS") {
            res.data.list.map((item) => {
              cphArray.push({ value: item.licensePlateNumber, ...item });
            });
            cb(cphArray);
          }
        });
      }
    },
    queryCph (query, index) {
      if (query) {
        setTimeout(() => {
          getVehicleList({
            licensePlateNumber: query,
            currentPage: 1,
            pageSize: 999,
            lineIds: [this.shiftIntercityObj.lineId]
          }).then((res) => {
            if (res.code === "SUCCESS") {
              this.form.dispatchDriverVehicles[index].cphList = res.data.list;
            }
          });
        }, 200);
      } else {
        getVehicleList({
          currentPage: 1,
          pageSize: 999,
          lineIds: [this.shiftIntercityObj.lineId]
        }).then((res) => {
          if (res.code === "SUCCESS") {
            this.form.dispatchDriverVehicles[index].cphList = res.data.list;
          }
        });
      }
    },
    setCurrentTime () {
      //定班：显示班次时间 流水班：显示当前时间---不要日期
      //需求调整，都是展示开始时间
      const { shiftType, departureDate, startDepartureTime } = this.shiftIntercityObj;
      let now = new Date(`${departureDate} ${startDepartureTime}`);
      // if (shiftType === 1) {
      //   now = new Date();
      // } else {
      //   now = new Date(`${departureDate} ${startDepartureTime}`);
      // }
      this.currentTime = this.formatTime(now.getHours(), now.getMinutes());
    },
    formatTime (hours, minutes) {
      return `${this.padStart(hours)}:${this.padStart(minutes)}`;
    },
    padStart (value) {
      return value.toString().padStart(2, '0');
    }
  },
  destroyed () {

  },
}
</script>
<style lang="scss" scoped>
/deep/.dialog-form {
  padding: 1.5vh 1vw !important;
}
.row-style {
  display: flex;
  /deep/ .el-form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 18px;
    .its_gy_delete {
      color: red;
      cursor: pointer;
    }
    .el-form-item__content {
      margin-left: 10px !important;
    }
  }
}
</style>