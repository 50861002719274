var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ticket-change" },
    [
      _c(
        "div",
        {
          staticClass: "exit",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.returnFun.apply(null, arguments)
            },
          },
        },
        [_c("i", { staticClass: "iconfont its_ba_fanhui" })]
      ),
      _c(
        "el-steps",
        {
          attrs: {
            active: _vm.ticketChangeActive,
            "align-center": "",
            "finish-status": "success",
          },
        },
        [
          _c("el-step", {
            attrs: {
              title: "确认改签信息",
              description: "请确认乘客信息是否有误",
            },
          }),
          _c("el-step", {
            attrs: {
              title: _vm.ticketChangeActive == 0 ? "改签班次" : "确认新班次",
              description: "修改新的班次",
            },
          }),
          _c("el-step", { attrs: { title: "完成", description: "" } }),
        ],
        1
      ),
      _vm.ticketChangeActive !== 2
        ? _c("phoneOrderDetial", {
            ref: "phoneOrderDetialRef",
            attrs: {
              from: "ticketChange",
              step: _vm.ticketChangeActive,
              ticketChangeForm: _vm.ticketChangeForm,
            },
            on: {
              placeOrder: _vm.placeOrder,
              watchOrderAmount: _vm.watchOrderAmount,
            },
          })
        : _c("div", { staticClass: "result-box" }, [
            _vm.isTicketChange
              ? _c("div", { staticClass: "success-text" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/images/home/success.svg"),
                    },
                  }),
                  _c("span", [_vm._v("改签成功")]),
                ])
              : _c("div", { staticClass: "error-text" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../../../assets/images/home/error.svg"),
                    },
                  }),
                  _c("span", [_vm._v("改签失败")]),
                ]),
          ]),
      _c("div", { staticClass: "button-box" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ticketChangeActive == 0,
                expression: "ticketChangeActive == 0",
              },
            ],
          },
          [
            _c("el-button", { on: { click: _vm.returnFun } }, [
              _vm._v("取消并返回"),
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.nextFun } },
              [_vm._v("信息确认无误")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ticketChangeActive == 1,
                expression: "ticketChangeActive == 1",
              },
            ],
          },
          [
            _c("el-button", { on: { click: _vm.previousFun } }, [
              _vm._v("上一步"),
            ]),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  disabled: _vm.isDisabledConfirmTicketFlag,
                },
                on: { click: _vm.confirmTicketChangeFun },
              },
              [_vm._v("确认改签")]
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.ticketChangeActive == 2,
                expression: "ticketChangeActive == 2",
              },
            ],
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.returnFun("isRequest")
                  },
                },
              },
              [_vm._v("返回上一页")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }